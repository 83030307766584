import { HiOutlineInformationCircle, HiPlusCircle } from 'react-icons/hi';
import { StatType } from '../../../../lib/utils/variables';
import { DataPickerModal } from '../../../modals/DataPickerModal';
import { VacationsInfoModal } from '../../../modals/VacationsInfoModal';
import { StatModalOption } from './DashboardStatistics';
import { StatiticsMock } from './Mock';

type DashboardStatiscticsItemProps = {
  stat: StatiticsMock;
  isModalVisible: (type: StatType, modalOption: StatModalOption) => boolean;
  onModalVisabilityChange: ({
    type,
    modalOption,
    value,
  }: {
    type: StatType;
    modalOption: StatModalOption;
    value: boolean;
  }) => void;
};

export const DashboardStatiscticsItem: React.FC<
  DashboardStatiscticsItemProps
> = ({ stat, isModalVisible, onModalVisabilityChange }) => {
  return (
    <div
      className="w-[200px] max-h-[150px] px-6 py-4 bg-white rounded flex flex-col justify-between"
      id={stat.title}
    >
      <div className="flex text-gray-500 justify-between">
        <p className=" text-sm pb-6">{stat.title}</p>
        {stat.isEditable && (
          <div className="flex space-x-1">
            <HiPlusCircle
              className="w-5 h-5 "
              onClick={() =>
                onModalVisabilityChange({
                  type: stat.type,
                  modalOption: StatModalOption.DATE,
                  value: true,
                })
              }
            />
            <HiOutlineInformationCircle
              className="w-5 h-5"
              onClick={() =>
                onModalVisabilityChange({
                  type: stat.type,
                  modalOption: StatModalOption.INFO,
                  value: true,
                })
              }
            />
            <VacationsInfoModal
              title={stat?.modalTitle || ''}
              isModalVisible={isModalVisible(stat.type, StatModalOption.INFO)}
              onClose={() =>
                onModalVisabilityChange({
                  type: stat.type,
                  modalOption: StatModalOption.INFO,
                  value: false,
                })
              }
            />
            <DataPickerModal
              isModalVisible={isModalVisible(stat.type, StatModalOption.DATE)}
              onSubmit={() => {}}
              onClose={() =>
                onModalVisabilityChange({
                  type: stat.type,
                  modalOption: StatModalOption.DATE,
                  value: false,
                })
              }
            />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <p className="text-3xl font-medium text-gray-900 pb-2">
          {stat.current}/{stat.goal}
          <span className="text-gary-900 text-xs ml-1">hrs</span>
        </p>
        <div className="w-full h-1 rounded-full bg-gray-100">
          <div
            className="h-1 rounded-full bg-green-600"
            style={{ width: (stat.current / stat.goal) * 100 }}
          />
        </div>
      </div>
    </div>
  );
};
