import { TeamsTable } from '../../../tables/TeamsTable';
import { HiPlus } from 'react-icons/hi';
import { useStore } from '../../../../lib/store';
import { EntityItem } from '../../../../lib/types/store';

export const ProjectTeamTab: React.FC = () => {
  const updateTeamsTableData = useStore((state) => state.updateTeamsTableData);
  const teamList = useStore((state) => state.teamList);
  const teamsTableData = useStore((state) => state.teamsTableData);

  const addFakeTeammate = () => {
    updateTeamsTableData([
      ...teamsTableData,
      {
        id:'0',
        name: 'Adam Smasher',
        roleId: '1',
        trackedTime: '160/160',
        latestUpdate: '11.10.2022',
        tasks: [],
        spentTime: '',
      },
    ]);
  };

  return (
    <form className="flex flex-col w-full h-full">
      <div className="w-full flex flex-col h-full">
        <div className="mx-6 py-8 border-gray-300 flex">
          <div className="text-gray-600 font-medium mr-6">
            <p className="whitespace-nowrap pr-2">Project team</p>
            <p className="text-gray-500 max-w-[250px] text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus
              amet lectus ultricies cras scelerisque dui fermentum.
            </p>
          </div>
          <label htmlFor="team" className="flex-1">
            <p className="text-sm text-gray-400">Team</p>
            <select
              id="team"
              className="w-full rounded p-2 border-gray-200 border-2 bg-white"
            >
              {teamList.map(({ id, name }: EntityItem) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="flex-1 px-5">
          <TeamsTable onDelete={() => {}} />
          <div className="flex justify-center py-6">
            <button
              type="button"
              className="bg-white border border-gray-500 text-gray-600 flex items-center px-4 py-2 rounded"
              onClick={() => addFakeTeammate()}
            >
              <HiPlus className="w-4 h-4" />
              Add teammate
            </button>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 border-t h-20 flex justify-end items-center px-4">
        <button type="button" className="bg-blue-500 text-white p-2 rounded ">
          Create report
        </button>
      </div>
    </form>
  );
};
