import {
  HiSortDescending,
  HiAdjustments,
  HiOutlineFolder,
} from 'react-icons/hi';
import { useStore } from '../../../lib/store';
import { useEffect, useState } from 'react';
import { Modal } from '../../modals/Modal';
import { TaskInfoModal } from '../../modals/TaskInfoModal';
import { showToast } from '../../../lib/toast';
import { TableData as TasksTableData } from '../../tables/TasksTable/Data';
import { TableData as TasksFoldersTableData } from '../../tables/TasksFoldersTable/Data';
import { MainLayout } from '../../layouts/MainLayout';
import { TasksTable } from '../../tables/TasksTable';
import { ModalMode } from '../../layouts/ModalLayout';
import classNames from 'classnames';
import { TasksFoldersTable } from '../../tables/TasksFoldersTable';
import shallow from 'zustand/shallow';

export const TasksPage = () => {
  const [isDetailVisible, setIsDetailsVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('0');
  const [isTaskList, setIsTaskList] = useState(true);
  const {
    tasksTableData,
    updateTasksTableData,
    cleanTasksTableData,
    updateTasksFoldersTableData,
    tasksFoldersTableData,
  } = useStore(
    (state) => ({
      tasksTableData: state.tasksTableData,
      updateTasksTableData: state.updateTasksTableData,
      cleanTasksTableData: state.cleanTasksTableData,
      updateTasksFoldersTableData: state.updateTasksFoldersTableData,
      tasksFoldersTableData: state.tasksFoldersTableData,
    }),
    shallow
  );
  const getTasksTableData = async () => {
    // TODO : Replace with not-fake API call when backend create endpoints
    if (!tasksFoldersTableData.length) {
      updateTasksFoldersTableData(TasksFoldersTableData);
    }
    if (!tasksTableData.length) {
      await fetch('http://localhost:3000')
        .then(() => {
          updateTasksTableData(
            TasksTableData.map((task) => {
              return {
                ...task,
                enteredTime: '100',
                plannedTime: '100',
                status: 'In process',
                startDate: '01.01.2000',
                jira: 'Jira link',
              };
            })
          );
        })
        .catch((error) =>
          showToast({
            type: 'error',
            title: 'Fetch error',
            message: 'response errror catched',
          })
        );
    }
  };
  const closeModal = () => {
    setIsDetailsVisible(false);
  };
  const openModalAndSetId = (value: string) => {
    setSelectedTaskId(value);
    setIsDetailsVisible(true);
  };

  useEffect(() => {
    getTasksTableData();
  }, []);

  return (
    <MainLayout title="Admin Page">
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-5 pb-4 px-4">
          <p className="font-black text-xl text-left"> Tasks</p>
          <div className="flex items-center justify-between text-sm space-x-4">
            <fieldset className="flex items-center">
              <p className="whitespace-nowrap pr-2 text-base text-gray-600">
                Show only unadded
              </p>
              <label
                htmlFor="added"
                className="inline-flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  defaultValue="added"
                  id="added"
                  name="added"
                  className="sr-only peer"
                />
                <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-500"></div>
              </label>
            </fieldset>
            <button
              className="border border-gray-100 rounded p-2 text-sm text-gray-500"
              onClick={() => getTasksTableData()}
            >
              Synchronize with trackers
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between py-4 px-4 border-y border-gray-100">
          <div className="flex space-x-4">
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center text-sm',
                {
                  'bg-gray-100': !isTaskList,
                }
              )}
              onClick={() => setIsTaskList(false)}
            >
              <HiOutlineFolder className="mr-1" />
              Folders view
            </button>
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center text-sm',
                {
                  'bg-gray-100': isTaskList,
                }
              )}
              onClick={() => setIsTaskList(true)}
            >
              <HiSortDescending className="mr-1" />
              Task list
            </button>
          </div>
          <button
            className="border border-gray-100 rounded p-2 flex items-center test-sm"
            onClick={() => cleanTasksTableData()}
          >
            <HiAdjustments className="fill-gray-500" />
            Filters
          </button>
        </div>
        {isTaskList ? (
          <TasksTable onItemClick={openModalAndSetId} />
        ) : (
          <TasksFoldersTable onItemClick={() => {}} />
        )}
      </div>
      <Modal mode={ModalMode.right} isModalVisible={isDetailVisible}>
        <TaskInfoModal taskId={selectedTaskId} closeModal={closeModal} />
      </Modal>
    </MainLayout>
  );
};
