import { HiOutlineCalendar } from 'react-icons/hi';
import {
  EntityItem,
  REPORT_COMMENTS_TYPE,
  REPORT_TEMPLATE,
} from '../../../../../lib/types/store';

export const PROJECT_LIST = [
  { id: '1', name: 'Project #1' },
  { id: '2', name: 'Project #2' },
  { id: '3', name: 'Project #3' },
  { id: '4', name: 'Project #4' },
  { id: '5', name: 'Project #5' },
  { id: '6', name: 'Project #6' },
];


type ChooseProjectProps = {
  template: REPORT_TEMPLATE;
  projectCount?: number;
};

export const ChooseProject: React.FC<ChooseProjectProps> = ({
  template,
  projectCount = 1,
}) => {
  return (
    <>
      {(template === REPORT_TEMPLATE.general ||
        template === REPORT_TEMPLATE.byPeople) &&
        Array(projectCount)
          .fill(null)
          .map((project, index) => (
            <div className="" key={project + index}>
              <label htmlFor="project" className="text-sm text-gray-400">
                Project {projectCount > 1 ? `#${index + 1}` : ''}
              </label>
              <select
                name={`Project ${index + 1}`}
                defaultValue={`Project ${
                  projectCount > 1 ? `#${index + 1}` : ''
                }`}
                className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
              >
                {PROJECT_LIST.map(({ id, name }: EntityItem) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          ))}
      <div className="relative">
        <label htmlFor="reportingPeriod" className="text-sm text-gray-400">
          {template === REPORT_TEMPLATE.general
            ? 'General Reporting period'
            : 'Reporting period'}
        </label>
        <input
          type="text"
          defaultValue="24.07.2022 - 25.08.2022"
          className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
        />
        <HiOutlineCalendar className="w-4 h-4 absolute bottom-4 right-2" />
      </div>
      {template === REPORT_TEMPLATE.general && (
        <>
          <div className="relative">
            <label
              htmlFor="monthlyReportingPeriod"
              className="text-sm text-gray-400"
            >
              Monthly reporting period
            </label>
            <input
              type="text"
              defaultValue="24.07.2022 - 25.08.2022"
              className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
            />
            <HiOutlineCalendar className="w-4 h-4 absolute bottom-4 right-2" />
          </div>
          <div className="">
            <label htmlFor="comments" className="text-sm text-gray-400">
              Type of comments
            </label>
            <select className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white">
              <option value={REPORT_COMMENTS_TYPE.byTask}>
                Task description when clicking on the field.
              </option>
              <option value={REPORT_COMMENTS_TYPE.byEmployee}>
                A separate page for each employee.
              </option>
            </select>
          </div>
        </>
      )}
    </>
  );
};
