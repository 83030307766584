import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  HiArrowLeft,
  HiChevronDown,
  HiDotsVertical,
  HiOutlineClipboardCheck,
  HiOutlineClock,
  HiTrash,
} from 'react-icons/hi';
import { NotificatonOptions } from '../../../lib/utils/variables';
import { UserInfoTab } from '../ModalTabsTemplates/UserInfoTab';
import { NotificationTab } from '../ModalTabsTemplates/NotificationTab';
import { useStore } from '../../../lib/store';
import { EntityItem, UserProfile } from '../../../lib/types/store';
import { UserHistoryTab } from '../ModalTabsTemplates/UserHistoryTab';
import { UserVacationsTab } from '../ModalTabsTemplates/UserVacationsTab';

export type UserProfileShape = {
  id: string;
  username?: string;
  lastName?: string;
  firstName?: string;
  name?: string;
  email?: string;
  password?: string;
  roleId?: string;
  positionId?: string;
  statusId?: string;
  projectId?: string;
  enteredTime?: string;
  teamId?: string;
  notifications?: {
    reports: NotificatonOptions;
    projects: NotificatonOptions;
    sickLeaves: NotificatonOptions;
    vacations: NotificatonOptions;
  };
};

export type UserInfoModalProps = {
  readonly userId: string;
  readonly title?: string;
  readonly closeModal: () => void;
};

const infoFields: {
  label: string;
  value: keyof Omit<UserProfile, 'notifications'>;
  mockedValue?: string;
}[] = [
  { label: 'PL ID', value: 'id' },
  { label: 'Related project', value: 'projectId', mockedValue: 'Project #1' },
  { label: 'Already entered time', value: 'enteredTime', mockedValue: '48' },
];

const userInfoTabs: { [x: string]: string } = {
  INFO: 'INFO',
  HISTORY: 'HISTORY',
  VACATIONS: 'VACATIONS',
  NOTIFICATIONS: 'NOTIFICATIONS',
};

export const UserInfoModal: React.FC<UserInfoModalProps> = ({
  userId,
  closeModal,
}) => {
  const updateUsersTableData = useStore((state) => state.updateUsersTableData);
  const getStatusById = useStore((state) => state.getStatusById);
  const statusList = useStore((state) => state.statusList);
  const usersTableData = useStore((state) => state.usersTableData);

  const [currentTab, setCurrentTab] = useState(userInfoTabs.INFO);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [isStatusDropdownVisible, setIsStatusDropdownVisible] =
    useState<boolean>(false);
  const user = usersTableData.find((tableUser) => tableUser.id === userId);

  const { register, setValue, formState, handleSubmit } =
    useForm<UserProfileShape>({
      defaultValues: {
        password: '123456',
        email: 'example@email.com',
        ...user,
      },
      mode: 'onChange',
    });

  const onSubmit = (data: any) => {
    const editedTable = usersTableData.map((user) => {
      if (user.id === data.id) return data;
      return user;
    });
    updateUsersTableData(editedTable);
    closeModal();
  };

  const onToggleChange = (variant: string, value: NotificatonOptions) => {
    setValue(
      `notifications.${
        variant as 'projects' | 'reports' | 'sickLeaves' | 'vacations'
      }`,
      value
    );
  };

  return (
    <div className="flex flex-col h-full flex-1">
      <div className="flex justify-between items-start px-4 rounded-t relative">
        <h3 className="mt-20 mb-6 max-h-[60px] overflow-y-hidden text-xl font-medium text-gray-900">
          {user?.name}
        </h3>
        <button
          type="button"
          className="absolute left-3 top-8 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={() => closeModal()}
        >
          <HiArrowLeft className="w-5 h-5 " />
        </button>
        <button
          type="button"
          className="absolute right-3 top-8 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        >
          <HiDotsVertical className="w-5 h-5 " />
        </button>
        <div
          className={classNames(
            'flex rounded-3xl bg-gray-300 items-center justify-center relative',
            { hidden: !isDropdownVisible }
          )}
        >
          <div
            id="dropdown"
            className="absolute z-10 w-36 bg-white rounded divide-y divide-gray-100 shadow top-10 right-6"
          >
            <ul className="text-sm text-gray-700">
              <li className="flex items-center mb-1 hover:bg-gray-100 p-2">
                <HiOutlineClock className="mr-2" />
                <p>Remind</p>
              </li>
              <li className="flex items-center mb-1 hover:bg-gray-100 p-2">
                <HiOutlineClipboardCheck className="mr-2" />
                <p>Export from PL</p>
              </li>
              <li className="flex items-center hover:bg-gray-100 p-2">
                <HiTrash className="mr-2" />
                <p>Delete</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-5">
        {infoFields.map((fieldName) => {
          if (user) {
            return (
              <div className="flex text-sm w-full mb-3 items-center">
                <div className="text-gray-500 w-36 ">{fieldName.label}</div>
                <div className="text-gray-800 py-1 px-2">
                  {user.hasOwnProperty(fieldName?.value)
                    ? user[fieldName?.value]
                    : fieldName.mockedValue}
                </div>
              </div>
            );
          }
          return null;
        })}
        <div className="flex text-sm w-full mb-6 items-center relative">
          <div className="text-gray-500 w-36">Status</div>
          <div className="text-orange-500 bg-orange-100 rounded-xl px-2 py-1">
            {user?.statusId && getStatusById(user?.statusId)}
          </div>
          <HiChevronDown
            className="h-5 w-5 ml-2"
            onClick={() => setIsStatusDropdownVisible(true)}
          />
          <div
            className={classNames(
              'flex rounded-3xl bg-gray-300 items-center justify-center relative',
              { hidden: !isStatusDropdownVisible }
            )}
          >
            <div
              id="dropdown"
              className="absolute z-10 w-36 bg-white rounded divide-y divide-gray-100 shadow top-0 left-0"
            >
              <ul className="text-sm text-gray-700">
                {statusList.map(({ id, name }: EntityItem) => (
                  <li
                    key={id}
                    value={id}
                    className="flex items-center mb-1 hover:bg-gray-100 p-2"
                    onClick={() => setIsStatusDropdownVisible(false)}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
        <ul className="flex flex-wrap -mb-p text-sm ml-2">
          {Object.keys(userInfoTabs).map((tab) => (
            <li>
              <button
                onClick={() => setCurrentTab(userInfoTabs[tab])}
                className={classNames(
                  'inline-block mx-2 mt-2 pb-2 rounded-t-lg border-b-2 lowercase first-letter:capitalize border-transparent hover:text-gray-600 hover:border-gray-300',
                  {
                    'text-black border-black': currentTab === userInfoTabs[tab],
                  }
                )}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {currentTab === userInfoTabs.INFO && (
        <UserInfoTab
          register={register}
          formState={formState}
          description={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus amet
            lectus ultricies cras scelerisque dui fermentum.`}
        />
      )}
      {currentTab === userInfoTabs.NOTIFICATIONS && (
        <NotificationTab onToggleChange={onToggleChange} />
      )}
      {currentTab === userInfoTabs.VACATIONS && <UserVacationsTab />}
      {currentTab === userInfoTabs.HISTORY && <UserHistoryTab />}
      {formState?.isDirty && (
        <div className="bg-gray-100 border-t h-20 flex justify-end items-center px-4">
          <button
            type="button"
            className="bg-blue-500 text-white p-2 rounded"
            onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Save Edited data
          </button>
        </div>
      )}
    </div>
  );
};
