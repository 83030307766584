import { ProjectsTable } from '../../tables/ProjectsTable';
import { MainLayout } from '../../layouts/MainLayout';
import { HiSortDescending, HiAdjustments } from 'react-icons/hi';
import { useStore } from '../../../lib/store';
import { TableData } from '../../tables/ProjectsTable/Data';
import { useEffect, useState } from 'react';
import { Modal } from '../../modals/Modal';
import { ModalMode } from '../../layouts/ModalLayout';
import {
  ProjectInfoModal,
  ProjectShape,
} from '../../modals/ProjectTableDetailModal';
import { showToast } from '../../../lib/toast';

export const ProjectsPage = () => {
  const updateProjectsTableData = useStore(
    (state) => state.updateProjectsTableData
  );
  const cleanProjectsTableData = useStore(
    (state) => state.cleanProjectsTableData
  );

  const [isDetailVisible, setIsDetailsVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectShape>({
    name: 'Dummy Name',
    client: {
      email: 'example@email.com',
      type: 'Dummy type',
    },
  });

  const getProjectsTableData = async () => {
    // TODO : Replace with not-fake API call when backend create endpoints
    await fetch('http://localhost:3000')
      .then(() => {
        updateProjectsTableData(TableData);
      })
      .catch((error) =>
        showToast({
          type: 'error',
          title: 'Fetch error',
          message: 'response errror catched',
        })
      );
  };
  const closeModal = () => setIsDetailsVisible(false);
  const openModal = () => {
    setIsDetailsVisible(true);
  };

  useEffect(() => {
    getProjectsTableData();
  }, []);

  return (
    <MainLayout title="Admin Page">
      <div className="flex items-center justify-between pt-5 pb-4 px-4">
        <p className="font-black text-xl text-left"> Projects</p>
        <div className="w-80 flex items-center justify-between text-gray-500 text-sm">
          <button
            className="border border-gray-100 rounded p-2"
            onClick={() => getProjectsTableData()}
          >
            Synchronize with trackers
          </button>
          <button
            className="border border-gray-100 rounded p-2 flex items-center"
            onClick={() => cleanProjectsTableData()}
          >
            <HiAdjustments className="fill-gray-500" />
            Filters
          </button>
          <button className="border border-gray-100 rounded p-2 flex items-center">
            <HiSortDescending className="fill-gray-500" />
            Sort
          </button>
        </div>
      </div>
      <ProjectsTable onItemClick={openModal} />
      <Modal mode={ModalMode.right} isModalVisible={isDetailVisible}>
        <ProjectInfoModal project={selectedProject} closeModal={closeModal} />
      </Modal>
    </MainLayout>
  );
};
