import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextClass } from './lib/toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <App />
    <ToastContainer
      toastClassName={(context) =>
        contextClass[context?.type || 'default'] +
        ' relative flex py-3 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-2 text-gray-700'
      }
    />
    <div id="root-modal" />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
