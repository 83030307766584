import { useForm } from 'react-hook-form';
import { DateRangeInput } from '../../../common/DateRangeInput';
import { SingleDateInput } from '../../../common/SingleDateInput';

type ReportForm = {
  reportType: string;
  startingDate: string;
  reportTemplate: string;
  reportingPeriod: string;
  author: string;
  emailTitle: string;
  emailBody: string;
  isReportForPeriod: boolean;
};

export const AutoReportForm: React.FC = () => {
  const { register, setValue } =
    useForm<ReportForm>({
      mode: 'onChange',
      defaultValues: {
        reportType: 'Daily',
        startingDate: '24.07.2022',
        reportTemplate: 'Template 1',
        reportingPeriod: '01.01.2022 - 01.02.2022',
      },
    });
  //TODO: refactor for getting report data from Store
  return (
    <form className="flex flex-col mx-auto max-w-[400px] max-h-[70vh] overflow-y-auto">
      <div className="px-4 mb-8 space-y-4 md:px-2 ">
        <div className="w-full">
          <label className="text-sm text-gray-400" htmlFor="reportType">
            Report Type *
          </label>
          <select
            {...register('reportType')}
            defaultValue="Monthly"
            className="w-full rounded p-2 mt-1.5 bg-white border-gray-200 border-2"
          >
            <option value="Monthly">Monthly</option>
            <option value="Weekly">Weekly</option>
            <option value="Daily">Daily</option>
          </select>
        </div>
        <div className="w-full relative">
          <label className="text-sm text-gray-400" htmlFor="startingDate">
            Starting date *
          </label>
          <SingleDateInput
            {...register('startingDate')}
            onDateChange={(date: string) => {
              setValue('startingDate', date);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="reportTemplate" className="text-sm text-gray-400">
            Report template *
          </label>
          <select
            placeholder="Role"
            {...register('reportTemplate')}
            defaultValue="Template 1"
            className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
          >
            <option value="Template 1">Template 1</option>
            <option value="Template 2">Template 2</option>
          </select>
        </div>
        <div className="relative">
          <label htmlFor="reportingPeriod" className="text-sm text-gray-400">
            Reporting period *
          </label>
          <DateRangeInput
            {...register('reportingPeriod')}
            onDateChange={(dates) => {
              setValue('reportingPeriod', `${dates[0]} - ${dates[1]}`);
            }}
          />
        </div>
        <div className="">
          <label htmlFor="author" className="text-sm text-gray-400">
            On whom need a report
          </label>
          <select
            placeholder="Enter a name"
            {...register('author')}
            className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
          >
            <option value="Andrew Paolo">Andrew Paolo</option>
            <option value="Narek Ara">Narek Ara</option>
          </select>
          <div className="flex flex-wrap pt-4">
            <div className="rounded-xl w-fit px-2 py-1 text-xs border border-gray-300 mr-1 mb-1 whitespace-nowrap">
              Andrew Paolo
            </div>
            <div className="rounded-xl w-fit px-2 py-1 text-xs border border-gray-300 mr-1 mb-1 whitespace-nowrap">
              Narek Ara
            </div>
            <div className="rounded-xl w-fit px-2 py-1 text-xs bg-black text-white mr-1 mb-1 whitespace-nowrap">
              Add a full team
            </div>
          </div>
        </div>
        <div className="">
          <label htmlFor="emailTitle" className="text-sm text-gray-400">
            Title of email
          </label>
          <input
            defaultValue="Value"
            {...register('emailTitle')}
            className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
          />
          <div className="flex items-center pt-2">
            <input
              type="checkbox"
              {...register('isReportForPeriod')}
              className="h-4 w-4 rounded mr-4 border-gray-400 "
            />
            <label htmlFor="isReportForPeriod" className="text-gray-500">
              Sign "Report for (period)"
            </label>
          </div>
        </div>
        <div className="">
          <label htmlFor="emailBody" className="text-sm text-gray-400">
            Email`s body
          </label>
          <textarea
            placeholder="Text"
            {...register('emailBody')}
            className="w-full h-40 rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
          />
        </div>
      </div>
    </form>
  );
};
