import api, { axiosRequest } from './api';

type UserService = {
  createUser: axiosRequest;
  getUsers: axiosRequest;
  getUser: axiosRequest;
  updateUser: axiosRequest;
  getRealmRole: axiosRequest;
  getUserRoles: axiosRequest;
  addUserRole: axiosRequest;
};

export const userService: UserService = {
  createUser: ({ headers = {} }) => {
    return api(headers).post('/api/keycloak/user/create');
  },
  getUsers: ({ headers = {} }) => {
    return api(headers).get('/api/keycloak/users');
  },
  getUser: ({ headers = {}, params }) => {
    return api(headers).get('/api/keycloak/user', { params });
  },
  updateUser: ({ headers = {}, params = {} }) => {
    return api(headers).put('/api/keycloak/user/update', params);
  },
  getRealmRole: ({ headers = {} }) => {
    return api(headers).get('/api/keycloak/realm/roles');
  },
  getUserRoles: ({ headers = {} }) => {
    return api(headers).get('/api/keycloak/user/roles');
  },
  addUserRole: ({ headers = {} }) => {
    return api(headers).post('/api/keycloak/user/roles/add');
  },
};
