import format from 'date-fns/format';
import React, { useState } from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';
import { shortDateFormat } from '../../../lib/utils/variables';
import { DateRangePicker } from '../DateRangePicker';

type DateRangeInputProps = {
  onDateChange: (date: [string, string]) => void;
};

export const DateRangeInput = React.forwardRef<
  HTMLInputElement,
  DateRangeInputProps
>(({ onDateChange, ...restProps }, ref) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const onPickerVisibleChange = () => {
    setIsPickerVisible(() => !isPickerVisible);
  };

  const onDateChangeFormatting = (dates: [Date | null, Date | null]) => {
    if (dates[1]) {
      onPickerVisibleChange();
    }

    const startDateFormatted = dates[0]
      ? format(dates[0], shortDateFormat)
      : '';
    const endDateFormatted = dates[1] ? format(dates[1], shortDateFormat) : '';

    return onDateChange([startDateFormatted, endDateFormatted]);
  };

  return (
    <div className="relative">
      <input
        ref={ref}
        onClick={() => onPickerVisibleChange()}
        type="text"
        className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
        {...restProps}
      />
      <HiOutlineCalendar
        className="w-4 h-4 absolute top-5 right-2"
        onClick={() => onPickerVisibleChange()}
      />
      {isPickerVisible && (
        <DateRangePicker onDateChange={onDateChangeFormatting} />
      )}
    </div>
  );
});
