import React from 'react';
import { HiArrowLeft, HiX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useStore } from '../../../lib/store';
import { REPORT_TEMPLATE } from '../../../lib/types/store';
import { ReportFormTableView } from '../../pages/createReportPages/reportForm/ReportFormTableView/ReportFormTableView';

type ReportFormLayoutProps = React.PropsWithChildren;

export const ReportFormLayout: React.FC<ReportFormLayoutProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const resetAllFormsStep = useStore((state) => state.resetAllFormsStep);
  const reportTemplate = useStore((state) => state.reportTemplate);
  const {
    byLabelsFormStep,
    byTasksFormStep,
    byPeopleFormStep,
    generalFormStep,
    updateFormStep,
  } = useStore(
    (state) => ({
      byLabelsFormStep: state.byLabelsFormStep,
      byPeopleFormStep: state.byPeopleFormStep,
      byTasksFormStep: state.byTasksFormStep,
      generalFormStep: state.generalFormStep,
      updateFormStep: state.updateFormStep,
    }),
    shallow
  );

  const onBack = () => {
    if (window?.location.pathname === '/createReport') {
      navigate(-1);
      return;
    }
    switch (reportTemplate) {
      case REPORT_TEMPLATE.byLabels:
        if (byLabelsFormStep === 1) {
          navigate(-1);
        }
        updateFormStep(byLabelsFormStep - 1, reportTemplate);
        break;
      case REPORT_TEMPLATE.byPeople:
        if (byPeopleFormStep === 1) {
          navigate(-1);
        }
        updateFormStep(byPeopleFormStep - 1, reportTemplate);
        break;
      case REPORT_TEMPLATE.byTasks:
        if (byTasksFormStep === 1) {
          navigate(-1);
        }
        updateFormStep(byTasksFormStep - 1, reportTemplate);
        break;
      case REPORT_TEMPLATE.general:
        if (generalFormStep === 1) {
          navigate(-1);
        }
        updateFormStep(generalFormStep - 1, reportTemplate);
        break;
      default:
        onClose();
    }
  };
  const onClose = () => {
    navigate('/dashboard');
    resetAllFormsStep();
  };

  return (
    <div className="flex flex-col w-full h-full min-h-screen bg-gray-50">
      <div className="flex justify-between w-full h-10 px-8 my-8">
        <div className="h-10 w-10 rounded-full bg-white flex justify-center items-center shadow">
          <HiArrowLeft className="w-6 h-6" onClick={() => onBack()} />
        </div>
        <div className="h-10 w-10 rounded-full bg-white flex justify-center items-center shadow">
          <HiX className="w-6 h-6" onClick={() => onClose()} />
        </div>
      </div>
      <div className="flex flex-1 w-full min-h-full">
        <div className="w-[40%] min-h-full">{children}</div>
        <div className="w-[60%] flex items-end">
          <ReportFormTableView />
        </div>
      </div>
    </div>
  );
};
