import React, { useState } from 'react';
import {
  HiPlus,
  HiOutlineBell,
  HiX,
  HiChevronDown,
  HiOutlineBookOpen,
} from 'react-icons/hi';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SearchBar } from '../../common/SearchBar';
import { Navigation } from './Navigation';
import { TrackedTimeModal } from '../../modals/TrackedTimeModal';

export type MainLayoutProps = {
  readonly title?: string;
};

export const MainLayout: React.FC<React.PropsWithChildren<MainLayoutProps>> = ({
  children,
  title,
}) => {
  const [isCreatingDropdownVisible, setIsCreatingDropdownVisible] =
    useState<boolean>(false);
  const [isTrackedTimeVisible, setIsTrackedTimeVisible] = useState(false);

  return (
    <div className="flex w-full min-h-screen">
      <div id="adminHeader" className="flex flex-col w-full">
        <div className="flex justify-between px-4 py-3 bg-white border-b-[1px] border-gray-100">
          <div className="w-3/5">
            <Navigation />
          </div>
          <div className="w-2/5 flex items-center">
            <SearchBar className="px-10 py-2 bg-gray-100 w-full mr-10 rounded text-sm text-gray-900 border-gray-300 focus:ring-blue-500 focus:border-blue-500" />
            <div className="flex justify-around items-center gap-1">
              <div className="relative cursor-pointer">
                <div
                  className="flex w-10 h-10 rounded-3xl  items-center justify-center"
                  onClick={() => setIsCreatingDropdownVisible(true)}
                >
                  <HiPlus className="w-5 h-5" />
                </div>
                <div
                  className={classNames(
                    'absolute border-solid border-b-white border-b-8 border-x-transparent translate-x-3 border-x-8 border-t-0 w-0 h-0 top-8 right-0 left-0',
                    { hidden: !isCreatingDropdownVisible }
                  )}
                />
                <div
                  id="dropdown"
                  className={classNames(
                    'absolute z-10 w-40 bg-white rounded divide-y divide-gray-100 shadow top-10',
                    { hidden: !isCreatingDropdownVisible }
                  )}
                >
                  <div
                    className="flex justify-between items-center text-gray-400 px-3 py-1.5 font-light text-xs"
                    onClick={() => {
                      setIsCreatingDropdownVisible(() => false);
                    }}
                  >
                    <p>Create</p>
                    <HiX className="w-5 h-5" />
                  </div>
                  <ul className="py-1 text-sm text-gray-700">
                    <li>
                      <Link
                        to="/createUser"
                        className="block py-1 px-2 hover:bg-gray-100"
                      >
                        User
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/createTeam"
                        className="block py-1 px-2 hover:bg-gray-100"
                      >
                        Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/createTask"
                        className="block py-1 px-2  hover:bg-gray-100"
                      >
                        Task
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/createProject"
                        className="block py-1 px-2  hover:bg-gray-100"
                      >
                        Project
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/createReport"
                        className="block py-1 px-2  hover:bg-gray-100"
                      >
                        Report
                      </Link>
                    </li>
                    <li>
                      <button
                        className="block py-1 px-2 text-left hover:bg-gray-100 w-full"
                        onClick={() => {
                          setIsCreatingDropdownVisible(false);
                          setIsTrackedTimeVisible(true);
                        }}
                      >
                        Tracked Time
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="flex w-10 h-10 rounded-3xl items-center justify-center">
                <HiOutlineBookOpen className="w-5 h-5" />
              </p>
              <p className="flex w-10 h-10 rounded-3xl items-center justify-center">
                <HiOutlineBell className="w-5 h-5" />
              </p>
              <Link
                className="flex w-10 h-10 rounded-3xl bg-blue-50 items-center justify-center"
                to="/settings"
              >
                <p>LA</p>
              </Link>
              <HiChevronDown className="w-5 h-5" />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full h-full bg-white">{children}</div>
      </div>
      <TrackedTimeModal
        isModalVisible={isTrackedTimeVisible}
        onClose={() => setIsTrackedTimeVisible(false)}
      />
    </div>
  );
};
