import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SingleDateInput } from '../../../common/SingleDateInput';
import { TeamsTable } from '../../../tables/TeamsTable';
import { TasksTable } from '../../../tables/TasksTable';

type ProjectsForm = {
  isCommercial: boolean;
  client: { name: string; email: string; type: string; timeZone: string };
  info: {
    endDate: string;
    startDate: string;
    description: string;
    jira: string;
    trello: string;
    comment: string;
  };
  team: string;
  tasks: string[];
};

export const CreateProjectForm = () => {
  const [isCommercial, setIsCommercial] = useState(false);
  // TODO : Split form sections into smaller components & add Automated Report Form
  const {
    setValue,
    register,
    formState: { isDirty },
    handleSubmit,
  } = useForm<ProjectsForm>({
    defaultValues: {
      client: {
        name: 'Alexander',
        email: 'test@email.com',
        type: 'Report once a month',
        timeZone: 'United States of America: time zone (GMT-5)',
      },
      info: {
        endDate: '01.02.2023',
        startDate: '01.01.2023',
        jira: 'Link',
        trello: 'Link',
        description:
          'Lorem ipsum dolor sit amet consectetur. Pellentesque egestas et at in turpis.',
      },

      team: 'Department EG',
      tasks: [''],
    },
  });

  const onChangeCommercial = (value: boolean) => {
    setIsCommercial(value);
  };

  const onSubmit = () => {
    // TODO: Connect Endpoint
  };

  return (
    <div className="h-full flex flex-col px-12 bg-gray-50 pb-6">
      <form
        className="pl-24 pr-8 py-4 bg-white shadow mb-14"
        onSubmit={() => handleSubmit(onSubmit)}
      >
        <p className="pt-10 pb-6 text-2xl text-gray-900 font-medium">
          Project Creator
        </p>
        <div className="px-1 py-8 border-y flex justify-between">
          <div className="w-1/5">
            <div className="flex items-center">
              <p className="text-gray-600 text-base pb-1 pr-2">
                Commercial project
              </p>
              <label className="inline-flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isCommercial}
                  className="sr-only peer"
                  onChange={() => onChangeCommercial(!isCommercial)}
                />
                <div className="w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:left-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-500"></div>
              </label>
            </div>
            <p className="text-gray-500 text-sm pb-2">
              If this item is disabled, a number of features will be missing.
            </p>
          </div>
          <div className="flex w-4/5 flex-col px-10">
            <div className="flex w-full">
              <div className="mr-6 mb-6">
                <label htmlFor="client.name" className="text-sm text-gray-400">
                  Client name
                </label>
                <input
                  {...register('client.name')}
                  className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700 font-normal mb-6"
                />
                <label htmlFor="client.type" className="text-sm text-gray-400">
                  Client type
                </label>
                <input
                  id="client.type"
                  {...register('client.type')}
                  className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700 font-normal"
                />
              </div>
              <div>
                <label htmlFor="client.email" className="text-sm text-gray-400">
                  Client email
                </label>
                <input
                  id="client.email"
                  {...register('client.email')}
                  className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700 font-normal mb-6"
                />
                <label
                  htmlFor="client.timeZone"
                  className="text-sm text-gray-400"
                >
                  Client timezone
                </label>
                <input
                  id="client.timeZone"
                  {...register('client.timeZone')}
                  className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700 font-normal"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-1 py-6 border-b flex justify-between">
          <div className="w-1/5">
            <p className="text-gray-600 text-base pb-1">Info</p>
            <p className="text-gray-500 text-sm pb-2">
              We need to add some specificity in the dates and descriptions.
            </p>
          </div>
          <div className="flex w-4/5 flex-col px-10">
            <div className="flex w-full">
              <div className="mr-6 mb-6 w-full">
                <div className="mb-4">
                  <label
                    htmlFor="info.startDate"
                    className="text-sm text-gray-400"
                  >
                    Start date
                  </label>
                  <SingleDateInput
                    {...register('info.startDate')}
                    onDateChange={(date: string) => {
                      setValue('info.startDate', date);
                    }}
                  />
                </div>
                <label
                  htmlFor="info.jira"
                  className="text-sm mt-4 text-gray-400"
                >
                  Jira link
                </label>
                <input
                  {...register('info.jira')}
                  id="info.jira"
                  className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700 font-normal"
                />
              </div>
              <div className="w-full">
                <div className="mb-4">
                  <label
                    htmlFor="info.endDate"
                    className="text-sm text-gray-400"
                  >
                    End date
                  </label>
                  <SingleDateInput
                    {...register('info.endDate')}
                    onDateChange={(date: string) => {
                      setValue('info.endDate', date);
                    }}
                  />
                </div>
                <label htmlFor="info.trello" className="text-sm text-gray-400">
                  Trello link
                </label>
                <input
                  {...register('info.trello')}
                  className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700 font-normal"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="info.description"
                className="text-sm text-gray-400"
              >
                Description
              </label>
              <textarea
                id="info.description"
                {...register('info.description')}
                className="w-full rounded p-2 mt-1.5 h-20 resize-none border-gray-200 border-2 bg-white text-gray-700 font-normal"
              />
            </div>
          </div>
        </div>
        <div className="px-1 py-6 flex justify-between border-b">
          <div className="w-1/5">
            <p className="text-gray-600 text-base pb-1">Team</p>
            <p className="text-gray-500 text-sm pb-2">
              Select the team members for which you want to make a project.
            </p>
            <button type="button" className="text-blue-500 text-base">
              Add one more teammate
            </button>
          </div>
          <div className="flex px-10 flex-col w-4/5">
            <div className="max-w-[400px]">
              <label htmlFor="team" className="text-sm text-gray-400">
                Team
              </label>
              <input
                id="team"
                {...register('team')}
                className="w-full rounded p-2 mt-1.5 mb-4 border-gray-200 border-2 bg-white text-gray-700 font-normal"
              />
            </div>
            <div className="w-full">
              <TeamsTable hiddenColumns={['id', 'latestUpdate', 'spentTime']} />
            </div>
          </div>
        </div>
        <div>
          <div className="px-1 py-8 flex">
            <div className="w-1/5">
              <p className="text-gray-600 text-base pb-1">Tasks</p>
              <p className="text-gray-500 text-sm pb-2">
                Let's choose which project you want and what period you need a
                report.
              </p>
              <button type="button" className="text-blue-500 text-base">
                Add one more task
              </button>
            </div>
            <div className="flex px-10 flex-col w-4/5">
              <div className="w-full border">
                <TasksTable
                  isCheckboxVisible={false}
                  hiddenColumns={[
                    'comment',
                    'isChecked',
                    'pl',
                    'projectId',
                    'id',
                  ]}
                  onItemClick={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="px-1 py-8 flex">
            <div className="w-1/5">
              <p className="text-gray-600 text-base pb-1">Comment</p>
              <p className="text-gray-500 text-sm pb-2">
                Your comments will only be seen by the DM and above.
              </p>
              <button type="button" className="text-blue-500 text-base">
                Add one more task
              </button>
            </div>
            <div className="flex px-10 flex-col w-4/5">
              <div>
                <label htmlFor="info.comment" className="text-sm text-gray-400">
                  Comment
                </label>
                <textarea
                  id="info.comment"
                  {...register('info.comment')}
                  className="w-full rounded p-2 mt-1.5 h-20 resize-none border-gray-200 border-2 bg-white text-gray-700 font-normal"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-10 py-2 rounded right-0 disabled:bg-blue-300"
            disabled={!isDirty}
          >
            Add project
          </button>
        </div>
      </form>
    </div>
  );
};
