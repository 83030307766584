const MINUTE_IN_MS = 60000;
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;
const MONTH_IN_MS = DAY_IN_MS * 30;
const YEAR_IN_MS = MONTH_IN_MS * 12;

export const getDatePrefix = (date: Date) => {
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  const miliseconds = Math.floor(new Date().getTime() - date.getTime());
  const absMiliseconds = Math.abs(miliseconds);
  const isFuture = miliseconds < 0;

  const yearInterval = Math.floor(absMiliseconds / YEAR_IN_MS);
  if (yearInterval > 0) {
    return isFuture ? 'in ' + yearInterval + 'y' : yearInterval + 'y ago';
  }

  const monthInterval = Math.floor(absMiliseconds / MONTH_IN_MS);
  if (monthInterval > 0) {
    return isFuture ? 'in ' + monthInterval + 'mth' : monthInterval + 'mth ago';
  }

  const dayInterval = Math.floor(absMiliseconds / DAY_IN_MS);
  if (dayInterval > 0) {
    return isFuture ? 'in ' + dayInterval + 'd' : dayInterval + 'd ago';
  }

  const hourInterval = Math.floor(absMiliseconds / HOUR_IN_MS);
  if (hourInterval > 0) {
    return isFuture ? 'in ' + hourInterval + 'h' : hourInterval + 'h ago';
  }

  const minuteInterval = Math.floor(absMiliseconds / MINUTE_IN_MS);
  if (minuteInterval > 0) {
    return isFuture ? 'in ' + minuteInterval + 'm' : minuteInterval + 'm ago';
  }

  return 'Now';
};
