import { HiChevronDown, HiOutlineTrash } from 'react-icons/hi';
import React, { useEffect } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { columnList, TableData } from './Data';
import { TeamsTableRow } from '../../../lib/types/tables';
import { showToast } from '../../../lib/toast';

type TeamsTableProps = {
  onDelete?: () => void;
  onCheckBoxClick?: (value: string, checked: boolean) => void;
  hiddenColumns?: (keyof TeamsTableRow)[];
  containerClassName?: string;
};

export const TeamsTable: React.FC<TeamsTableProps> = ({
  onDelete,
  hiddenColumns = ['spentTime', 'tasks', 'id'],
  onCheckBoxClick,
  containerClassName,
}) => {
  const getRoleById = useStore((state) => state.getRoleById);
  const teamsTableData = useStore((state) => state.teamsTableData);
  const updateTeamsTableData = useStore((state) => state.updateTeamsTableData);

  const data = React.useMemo<TeamsTableRow[]>(
    () =>
      teamsTableData.map(
        ({ id, name, roleId, trackedTime, latestUpdate, tasks, spentTime }) => {
          return {
            id,
            name: name || '-',
            roleId: getRoleById(roleId) || '-',
            trackedTime: trackedTime || '-',
            latestUpdate: latestUpdate || '-',
            tasks,
            spentTime: spentTime || '',
          };
        }
      ),
    [teamsTableData, getRoleById]
  );
  const columns = React.useMemo<Column<TeamsTableRow>[]>(() => columnList, []);
  const initialState = { hiddenColumns };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  const onFiltersClick = async (data: any) => {
    getFilteredData(data.id);
  };
  const getFilteredData = async (filter: string) => {
    await fetch('http://localhost:3000')
      .then(() => {
        updateTeamsTableData(TableData);
      })
      .catch((error) =>
        showToast({
          type: 'error',
          title: 'Fetch error',
          message: 'response errror catched',
        })
      );
  };

  useEffect(() => {
    updateTeamsTableData(TableData);
  }, []);

  return (
    <table
      {...getTableProps()}
      className={'w-full text-xs border border-gray-200 ' + containerClassName}
    >
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div
                  className="flex justify-start items-center relative "
                  onClick={(event) => onFiltersClick(column)}
                >
                  {onCheckBoxClick && column.id === 'name' && (
                    <input
                      // TODO: Select all on Change
                      onChange={() => {}}
                      type="checkbox"
                      className="w-3 h-3 rounded-xl mr-2"
                    />
                  )}
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="h-10 text-left border-b">
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4 mr-auto">
                    <div className="flex items-center justify-start">
                      {onCheckBoxClick && cell.column.id === 'name' && (
                        <input
                          onChange={(e) =>
                            onCheckBoxClick(row.values.id, e.target.checked)
                          }
                          type="checkbox"
                          className="w-3 h-3 rounded-xl mr-2"
                        />
                      )}
                      {cell.column.id === 'tasks' ? (
                        <div className="text-sm text-gray-700 flex items-center">
                          <p>{cell.value.length}</p>
                          <HiChevronDown className="text-gray-400 w-5 h-5" />
                        </div>
                      ) : (
                        cell.render('Cell')
                      )}
                      {cell.column.id === 'latestUpdate' && (
                        <HiOutlineTrash
                          className="w-6 h-6 bg-gray-300 rounded-3xl p-1 mx-1"
                          onClick={() => onDelete!()}
                        />
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
