import React from 'react';
import { ReportFormByPeople } from './ReportFormByPeople';
import { ReportFormLayout } from '../../../layouts/ReportFormLayout';

export const ReportByPeoplePage: React.FC = () => {
  return (
    <ReportFormLayout>
      <ReportFormByPeople />
    </ReportFormLayout>
  );
};
