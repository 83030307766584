import { useEffect, useState } from 'react';
import { UserProfileShape } from '../../modals/UserInfoModal';
import { UserInfoTab } from '../../modals/ModalTabsTemplates/UserInfoTab';
import { NotificationTab } from '../../modals/ModalTabsTemplates/NotificationTab';
import classNames from 'classnames';
import { NotificatonOptions } from '../../../lib/utils/variables';
import { useForm } from 'react-hook-form';
import { MainLayout } from '../../layouts/MainLayout';
import { userService } from '../../../services/userServices';
import { showToast } from '../../../lib/toast';
import keycloak from '../../../keycloak';
import { useStore } from '../../../lib/store';

export const SettingsPage = () => {
  const updateUserProfile = useStore((state) => state.updateUserProfile);
  const userProfile = useStore((state) => state.userProfile);
  const [isInfoTab, setIsInfoTab] = useState(true);
  const currentUser: UserProfileShape = {
    id: '0',
    name: 'Dummy Name',
    teamId: '1',
    roleId: '1',
    statusId: '1',
    positionId: '1',
    email: 'example@email.com',
    password: '1234567c',
  };
  const { register, setValue, formState, reset } = useForm<UserProfileShape>({
    mode: 'onChange',
    defaultValues: currentUser,
  });

  const onToggleChange = (variant: string, value: NotificatonOptions) => {
    setValue(
      `notifications.${
        variant as 'projects' | 'reports' | 'sickLeaves' | 'vacations'
      }`,
      value
    );
  };

  const getUserProfileRequest = async () => {
    await keycloak.loadUserInfo();
    const id = keycloak.idTokenParsed?.sub;

    try {
      const response = await userService.getUser({ params: { id } });
      const userProfileParsed = JSON.parse(response.data);

      if (userProfileParsed.id) {
        await updateUserProfile(userProfileParsed);

        showToast({ type: 'success', title: 'Success' });
      }
    } catch (e) {
      showToast({ type: 'error', title: 'Unexpected error' });
      return;
    }
  };

  useEffect(() => {
    if (userProfile.id) {
      reset(userProfile);
    } else {
      if (keycloak.token) {
        getUserProfileRequest();
      }
    }
  }, []);

  return (
    <MainLayout>
      <div className="flex flex-col">
        <div>
          <div className="flex justify-between items-start p-4 rounded-t">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Settings
            </h3>
          </div>
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
            <ul className="flex flex-wrap text-sm px-8 space-x-4">
              <li>
                <button
                  onClick={() => setIsInfoTab(true)}
                  className={classNames(
                    'inline-block py-3 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                    { 'text-black border-black': isInfoTab }
                  )}
                >
                  Info
                </button>
              </li>
              <li>
                <button
                  onClick={() => setIsInfoTab(false)}
                  className={classNames(
                    'inline-block py-3 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                    { 'text-black border-black': !isInfoTab }
                  )}
                >
                  Notification
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-[420px] pl-5 pt-5">
          {isInfoTab ? (
            <UserInfoTab register={register} formState={formState} />
          ) : (
            <NotificationTab onToggleChange={onToggleChange} />
          )}
        </div>
      </div>
    </MainLayout>
  );
};
