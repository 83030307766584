import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../lib/styles/customDatepickerStyles.css';
import { shortDateFormat } from '../../../lib/utils/variables';

type DateRangePickerProps = {
  onDateChange: (date: Date) => void;
};

export const SingleDatePicker: React.FC<DateRangePickerProps> = ({
  onDateChange,
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const onChange = (date: Date) => {
    setStartDate(date);

    if (date) {
      onDateChange(date);
    }
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      dateFormat={shortDateFormat}
      startDate={startDate}
      inline
    />
  );
};
