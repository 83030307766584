import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import keycloak from '../keycloak';

type Api = (props: { readonly headers?: AxiosRequestHeaders }) => AxiosInstance;

const api: Api = ({ headers = {} }) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };

  const apiToken = keycloak.token;
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI,
    timeout: 10000,
    headers: {
      ...defaultHeaders,
      ...(apiToken && {
        Authorization: `Bearer ${apiToken}`,
        Accept: 'application/json',
      }),
      ...headers,
    },
    responseType: 'json',
  });

  client.interceptors.request.use(
    (request) => request,
    (error) => error
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => error
  );

  return client;
};

export default api;

export type axiosRequest = ({
  headers,
  params,
}: {
  headers?: Record<string, string>;
  params?: Record<string, any>;
}) => Promise<AxiosResponse>;
