import {
  HiSortDescending,
  HiAdjustments,
  HiOutlineFolder,
} from 'react-icons/hi';
import { useStore } from '../../../lib/store';
import { useEffect, useState } from 'react';
import { Modal } from '../../modals/Modal';
import { UserInfoModal } from '../../modals/UserInfoModal';
import { showToast } from '../../../lib/toast';
import { ModalMode } from '../../layouts/ModalLayout';
import { MainLayout } from '../../layouts/MainLayout';
import { UsersTable } from '../../tables/UsersTable';
import classNames from 'classnames';
import { TeamsFoldersTable } from '../../tables/TeamsFoldersTable';
import shallow from 'zustand/shallow';
import { userService } from '../../../services/userServices';
import keycloak from '../../../keycloak';

export const UsersPage = () => {
  const [isDetailVisible, setIsDetailsVisible] = useState(false);
  const [isTeamsList, setIsTeamsList] = useState(false);
  // TODO : refactor when backend realize endpoints
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const {
    updateUsersTableData,
    cleanUsersTableData,
    usersTableData,
    updateTeamsFoldersTableData,
  } = useStore(
    (state) => ({
      updateUsersTableData: state.updateUsersTableData,
      cleanUsersTableData: state.cleanUsersTableData,
      usersTableData: state.usersTableData,
      updateTeamsFoldersTableData: state.updateTeamsFoldersTableData,
    }),
    shallow
  );
  const getUsersRequest = async () => {
    try {
      const response = await userService.getUsers({});
      // TODO: update when data will be suited for table 
      // const usersDataParsed = JSON.parse(response.data);
      // updateUsersTableData(usersDataParsed)

      showToast({ type: 'success', title: 'Success' });
    } catch (e) {
      showToast({ type: 'error', title: 'Unexpected error' });
      return;
    }
  };

  const closeModal = () => {
    setIsDetailsVisible(false);
  };
  const openModalAndSetId = (value: string) => {
    setSelectedUserId(value);
    setIsDetailsVisible(true);
  };

  useEffect(() => {
    if (keycloak.token) {
      getUsersRequest();
    }
  }, []);

  return (
    <MainLayout title="Admin Page">
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-5 pb-4 px-4">
          <p className="font-black text-xl text-left"> Users</p>
          <button
            className="border border-gray-100 rounded p-2 text-sm text-gray-500"
            onClick={() => getUsersRequest()}
          >
            Synchronize with trackers
          </button>
        </div>
        <div className="flex items-center justify-between py-4 px-4 border-y border-gray-100">
          <div className="flex space-x-4">
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center text-sm',
                {
                  'bg-gray-100': isTeamsList,
                }
              )}
              onClick={() => setIsTeamsList(true)}
            >
              <HiOutlineFolder className="mr-1" />
              Teams view
            </button>
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center text-sm',
                {
                  'bg-gray-100': !isTeamsList,
                }
              )}
              onClick={() => setIsTeamsList(false)}
            >
              <HiSortDescending className="mr-1" />
              Users list
            </button>
          </div>
          <button
            className="border border-gray-100 rounded p-2 flex items-center test-sm"
            onClick={() => cleanUsersTableData()}
          >
            <HiAdjustments className="fill-gray-500" />
            Filters
          </button>
        </div>
        {isTeamsList ? (
          <TeamsFoldersTable />
        ) : (
          <UsersTable onItemClick={openModalAndSetId} />
        )}
      </div>
      <Modal mode={ModalMode.right} isModalVisible={isDetailVisible}>
        <UserInfoModal userId={selectedUserId} closeModal={closeModal} />
      </Modal>
    </MainLayout>
  );
};
