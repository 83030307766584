import { HiOutlineDownload, HiOutlineTrash } from 'react-icons/hi';
import React, { useEffect } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { columnList, TableData } from './Data';
import { ReportsTableRow } from '../../../lib/types/tables';
import { showToast } from '../../../lib/toast';

type ReportsTableProps = {
  onDelete: () => void;
  onDownload: () => void;
};

type GetReportsData = {
  sortBy?: string;
  page?: number;
  sortFromHighToLow?: boolean;
};

export const ReportsTable: React.FC<ReportsTableProps> = ({
  onDelete,
  onDownload,
}) => {
  const reportsTableData = useStore((state) => state.reportsTableData);
  const updateReportsTableData = useStore(
    (state) => state.updateReportsTableData
  );
  const data = React.useMemo<ReportsTableRow[]>(
    () => reportsTableData,
    [reportsTableData]
  );
  const columns = React.useMemo<Column<ReportsTableRow>[]>(
    () => columnList,
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const onFiltersClick = async (data: any) => {
    getReportsData({ sortBy: data.id });
  };

  const getReportsData = async (data?: GetReportsData) => {
    await fetch('http://localhost:3000')
      .then(() => {
        updateReportsTableData(TableData);
      })
      .catch((error) =>
        showToast({
          type: 'error',
          title: 'Fetch error',
          message: 'response errror catched',
        })
      );
  };

  useEffect(() => {
    getReportsData();
  }, []);

  return (
    <table
      {...getTableProps()}
      className="w-full text-xs border border-gray-200"
    >
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div
                  className="flex justify-start items-center relative "
                  onClick={(event) => onFiltersClick(column)}
                >
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="h-10 text-left border-b">
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4 mr-auto">
                    <div className="flex items-center justify-between">
                      {cell.render('Cell')}
                      {cell.column.id === 'author' && (
                        <div className="flex">
                          <HiOutlineDownload className="w-6 h-6 bg-gray-300 rounded-3xl p-1 mx-1" />
                          <HiOutlineTrash className="w-6 h-6 bg-gray-300 rounded-3xl p-1 mx-1" />
                        </div>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
