import React from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { columnList, TableData } from './Data';
import { VacationsTableRow } from '../../../lib/types/tables';

export const VacationsTable: React.FC = () => {
  const data = React.useMemo<VacationsTableRow[]>(() => TableData, []);
  const columns = React.useMemo<Column<VacationsTableRow>[]>(
    () => columnList,
    []
  );
  const initialState = { hiddenColumns: ['id'] };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  return (
    <table
      {...getTableProps()}
      className="w-full text-xs border border-gray-200"
    >
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div className="flex justify-start items-center relative ">
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="h-10 text-left border-b">
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4 mr-auto">
                      {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
