import React from 'react';
import classNames from 'classnames';

export enum ModalMode {
  center = 'CENTER',
  right = 'RIGHT',
}

export type ModalLayoutProps = {
  readonly mode?: ModalMode;
};

export const ModalLayout: React.FC<React.PropsWithChildren<ModalLayoutProps>> = ({
  children,
  mode,
}) => {
  const isCentered = mode === ModalMode.center;

  return (
    <div
      className={classNames(
        'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full inset-0 h-full bg-gray-400 bg-opacity-50',
        {
          'flex items-center justify-center': isCentered,
          'flex justify-end': !isCentered,
        }
      )}
    >
      <div
        className={classNames('relative h-auto', {
          'p-4 w-full max-w-2xl': isCentered,
          'w-full md:w-1/2': !isCentered,
        })}
      >
        <div
          className={classNames('relative bg-white', {
            'rounded-lg': isCentered,
            'h-full flex flex-col flex-1': !isCentered,
          })}
        >
          <div className="flex-1">{children}</div>
        </div>
      </div>
    </div>
  );
};