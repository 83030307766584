import React from 'react';
import { ReportFormByTasks } from './ReportFormByTasks';
import { ReportFormLayout } from '../../../layouts/ReportFormLayout';

export const ReportByTasksPage: React.FC = () => {
  return (
    <ReportFormLayout>
      <ReportFormByTasks />
    </ReportFormLayout>
  );
};
