import { RecordMessage } from '../../../../lib/types/entities';

export const recordsTabs: { [x: string]: string } = {
  ALL: 'All',
  RECENT_TASK: 'Recent Task',
  TASK_INFO: 'Task Info',
  REPORTS: 'Reports',
  TEAM: 'Team',
};

export const messagesMock: RecordMessage[] = [
  {
    createdAt: new Date(),
    title: 'At Project 1: #Lorem ipsum dolor sit amet consectetur. add 1 hour',
    info: 'Lorem ipsum dolor sit amet consectetur. Sit quis libero malesuada tortor amet vitae.',
  },
  {
    createdAt: new Date(new Date().setHours(10)),
    title:
      'You need to track the time at Project 1: #Lorem ipsum dolor sit amet consectetur. to 16:00 ',
    isTrackTimeReminder: true,
  },
  {
    createdAt: new Date(new Date().setHours(4)),
    title:
      'Bernard Singleton (Backend developer) take vacation from 11.09.2022 to 25.11.2022',
    vacationStart: '11.09.2022',
    vacationEnd: '25.11.2022',
  },
  {
    createdAt: new Date(new Date().setHours(9)),
    title: 'You added to Project 1: #Lorem ipsum dolor sit amet consectetur.',
  },
  {
    createdAt: new Date(Date.now() - 86400 * 1000),
    title: 'At Project 1: #Lorem ipsum dolor sit amet consectetur. add 1 hour',
    info: 'Lorem ipsum dolor sit amet consectetur. Sit quis libero malesuada tortor amet vitae.',
  },
  {
    createdAt: new Date(new Date(Date.now() - 86400 * 1000).setHours(11)),
    title:
      'You need to track the time at Project 1: #Lorem ipsum dolor sit amet consectetur. to 16:00 ',
    isTrackTimeReminder: true,
  },
  {
    createdAt: new Date(new Date(Date.now() - 86400 * 1000).setHours(4)),
    title:
      'Bernard Singleton (Backend developer) take vacation from 11.09.2022 to 25.11.2022',
    vacationStart: '11.09.2022',
    vacationEnd: '25.11.2022',
  },
  {
    createdAt: new Date(new Date(Date.now() - 86400 * 1000).setHours(9)),
    title: 'You added to Project 1: #Lorem ipsum dolor sit amet consectetur.',
  },
];