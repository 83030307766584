import { useStore } from '../../../../../lib/store';
import { SearchBar } from '../../../../common/SearchBar';
import { TeamsTable } from '../../../../tables/TeamsTable';

export const ChooseTeam: React.FC = () => {
  const teamMemberList = useStore((state) => state.teamMemberList);
  const updateTeamMemberList = useStore((state) => state.updateTeamMemberList);
  return (
    <>
      <div className="bg-white">
        <SearchBar className="p-2.5 pl-10 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" />
      </div>
      <div className="bg-white border border-gray-100 rounded-lg overflow-y-auto max-h-[550px]">
        <TeamsTable
          hiddenColumns={['latestUpdate', 'trackedTime', 'id']}
          onCheckBoxClick={(id, checked) => {
            if (checked) {
              updateTeamMemberList([...teamMemberList, id]);
            } else {
              updateTeamMemberList(
                teamMemberList.filter((member) => member !== id)
              );
            }
          }}
        />
      </div>
    </>
  );
};
