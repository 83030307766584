import classNames from 'classnames';
import {
  HiCheckCircle,
  HiClock,
  HiEmojiSad,
  HiExclamationCircle,
} from 'react-icons/hi';

export const getIconByType = (type: string) => {
  const iconByType =
    type === 'success' ? (
      <HiCheckCircle className="w-5 h-5 text-white" />
    ) : type === 'info' ? (
      <HiEmojiSad className="w-5 h-5 text-white" />
    ) : type === 'default' ? (
      <HiClock className="w-5 h-5 text-white" />
    ) : (
      <HiExclamationCircle className="w-5 h-5 text-white" />
    );

  return (
    <div className="flex px-2 items-center">
      <div
        className={classNames('rounded-full p-1', {
          'bg-orange-300': type === 'warning',
          'bg-red-300': type === 'error',
          'bg-green-300': type === 'success',
          'bg-indigo-300': type === 'default' || type === 'info',
        })}
      >
        {iconByType}
      </div>
    </div>
  );
};
