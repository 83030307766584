import React from 'react';
import { AdminFormLayout } from '../../layouts/FormLayout';
import { CreateReportForm } from './CreateReportForm';

export const CreateReportPage: React.FC = () => {
  return (
    <AdminFormLayout>
      <CreateReportForm />
    </AdminFormLayout>
  );
};
