import { UserProfileShape } from '../../components/modals/UserInfoModal';
import {
  HistoryTableData,
  ProjectsTableData,
  ReportsTableData,
  TasksFoldersTableRow,
  TasksTableRow,
  TeamsFoldersTableRow,
  TeamsTableData,
} from './tables';

export enum REPORT_TEMPLATE {
  general = 'general',
  byPeople = 'byPeople',
  byLabels = 'byLabels',
  byTasks = 'byTasks',
}
export enum REPORT_COMMENTS_TYPE {
  byEmployee = 'BY_EMLOYEE',
  byTask = 'BY_TASK',
}

export type UserProfile = UserProfileShape;
export type TasksStoreItem = TasksTableRow & {
  enteredTime?: string;
  plannedTime?: string;
  status?: string;
  startDate?: string;
  link?: string;
  taskType?: string;
};
export type EntityItem = { name: string; id: string };
export type PmItem = { name: string; id: string };
export type ClientItem = {
  name: string;
  id: string;
  type: string;
  email: string;
};

export type ReportFormSlice = {
  reportTemplate: REPORT_TEMPLATE;
  commentsType: REPORT_COMMENTS_TYPE;
  generalFormStep: number;
  byLabelsFormStep: number;
  byPeopleFormStep: number;
  byTasksFormStep: number;
  reportingPeriod: string;
  monthlyReportingPeriod: string;
  clientIdList: string[];
  projectIdList: string[];
  teamMemberList: string[];
  resetAllFormsStep: () => void;
  updateFormStep: (newStep: number, template: REPORT_TEMPLATE) => void;
  updateClientIdList: (newData: string[]) => void;
  updateProjectIdList: (newData: string[]) => void;
  updateTeamMemberList: (newData: string[]) => void;
  updateReportingPeriod: (newPeriod: String) => void;
  updateReportTemplate: (newTemplate: REPORT_TEMPLATE) => void;
};
export type UsersSlice = {
  usersTableData: UserProfile[];
  updateUsersTableData: (newData: UserProfile[]) => void;
  cleanUsersTableData: () => void;
};
export type UserProfileSlice = {
  userProfile: UserProfile;
  updateUserProfile: (newData: UserProfile) => void;
  cleanUserProfile: () => void;
};
export type ProjectsSlice = {
  projectsTableData: ProjectsTableData;
  updateProjectsTableData: (newData: ProjectsTableData) => void;
  cleanProjectsTableData: () => void;
};
export type TasksSlice = {
  tasksTableData: TasksStoreItem[];
  updateTasksTableData: (newData: TasksStoreItem[]) => void;
  cleanTasksTableData: () => void;
};

export type ReportsSlice = {
  reportsTableData: ReportsTableData;
  updateReportsTableData: (newData: ReportsTableData) => void;
  cleanReportsTableData: () => void;
};

export type TeamsSlice = {
  teamsTableData: TeamsTableData;
  updateTeamsTableData: (newData: TeamsTableData) => void;
  cleanTeamsTableData: () => void;
};

export type TeamsFoldersSlice = {
  teamsFoldersTableData: TeamsFoldersTableRow[];
  updateTeamsFoldersTableData: (newData: TeamsFoldersTableRow[]) => void;
  cleanTeamsFoldersTableData: () => void;
};

export type TasksFoldersSlice = {
  tasksFoldersTableData: TasksFoldersTableRow[];
  updateTasksFoldersTableData: (newData: TasksFoldersTableRow[]) => void;
  cleanTasksFoldersTableData: () => void;
};

export type HistorySlice = {
  historyTableData: HistoryTableData;
  updateHistoryTableData: (newData: HistoryTableData) => void;
  cleanHistoryTableData: () => void;
};

export type EntityListSlice = {
  roleList: EntityItem[];
  statusList: EntityItem[];
  teamList: EntityItem[];
  positionList: EntityItem[];
  pmList: PmItem[];
  clientList: ClientItem[];
  clientTypeList: EntityItem[];
  getRoleById: (value: string) => string | null;
  getStatusById: (value: string) => string | null;
  getTeamById: (value: string) => string | null;
  getPositionById: (value: string) => string | null;
  getPmById: (value: string) => string | null;
  getClientById: (value: string) => ClientItem | null;
  getClientTypeById: (value: string) => string | null;
};
