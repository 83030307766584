import format from 'date-fns/format';
import { ToolbarProps, Navigate } from 'react-big-calendar';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import '../Calendar/calendar.css';

export const CalendarToolbar = (props: ToolbarProps) => {
  const { onNavigate } = props;

  return (
    <div className="flex px-5 items-center justify-between flex-nowrap bg-gray-100">
      <p className="text-gray-500 text-base font-bold py-5">
        {format(props.date, 'MMMM yyyy')}
      </p>
      <div className="flex">
        <div className="flex">
          <HiChevronLeft
            className="w-5 h-5 fill-gray-500"
            onClick={() => onNavigate(Navigate.PREVIOUS)}
          />
          <span
            className="p-2"
            onClick={() => props.onNavigate(Navigate.TODAY)}
          />
          <HiChevronRight
            className="w-5 h-5 fill-gray-500"
            onClick={() => onNavigate(Navigate.NEXT)}
          />
        </div>
      </div>
    </div>
  );
};
