import { UseFormRegister, UseFormStateReturn } from 'react-hook-form';
import { useStore } from '../../../../lib/store';
import { EntityItem } from '../../../../lib/types/store';
import { UserProfileShape } from '../../UserInfoModal';
import shallow from 'zustand/shallow';
import classNames from 'classnames';

type UserInfoTabProps = {
  formState?: UseFormStateReturn<UserProfileShape>;
  register: UseFormRegister<UserProfileShape>;
  description?: string;
};

export const UserInfoTab: React.FC<UserInfoTabProps> = ({
  register,
  formState,
  description,
}) => {
  const { roleList, teamList, positionList, statusList } = useStore(
    (state) => ({
      roleList: state.roleList,
      teamList: state.teamList,
      positionList: state.positionList,
      statusList: state.statusList,
    }),
    shallow
  );

  //TODO: refactor for getting user by id from userList in State
  return (
    <section
      className={classNames('flex w-full border-b border-gray-200', {
        'p-4': !!description,
      })}
    >
      {!!description && (
        <div className="w-1/2 text-base font-medium text-gray-600 pr-8">
          User
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      )}
      <form className="flex flex-col">
        <div className="px-4 mb-8 space-y-4 md:px-2">
          <div className="w-full">
            <label className="text-sm text-gray-400" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              {...register('email')}
              className="w-full rounded p-2 selected:bg-white border-gray-200 border-2"
            />
            <p className="text-red-500 italic text-xs pb-1 mb-2 hidden">
              {formState?.errors.email?.message}
            </p>
          </div>
          <div className="w-full relative">
            <label className="text-sm text-gray-400" htmlFor="password">
              Password
            </label>
            <input
              // TODO: add eye icon to hide\show password value
              type="password"
              {...register('password')}
              className="w-full rounded p-2 border-gray-200 border-2"
            />
            <p className="text-red-500 italic text-xs pb-1 hidden">
              {formState?.errors.password?.message && 'Not valid password'}
            </p>
          </div>
          <div className="">
            <label htmlFor="roleId" className="text-sm text-gray-400">
              Role
            </label>
            <select
              placeholder="Role"
              {...register('roleId')}
              className="w-full rounded p-2 border-gray-200 border-2 bg-white"
            >
              {roleList.map(({ id, name }: EntityItem) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <label htmlFor="positionId" className="text-sm text-gray-400">
              Position
            </label>
            <select
              {...register('positionId')}
              className="w-full rounded p-2 border-gray-200 border-2 bg-white"
            >
              {positionList.map(({ id, name }: EntityItem) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <label htmlFor="team" className="text-sm text-gray-400">
              Team
            </label>
            <select
              {...register('teamId')}
              className="w-full rounded p-2 border-gray-200 border-2 bg-white"
            >
              {teamList.map(({ id, name }: EntityItem) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <label htmlFor="status" className="text-sm text-gray-400">
              Status
            </label>
            <select
              {...register('statusId')}
              className="w-full rounded p-2 border-gray-200 border-2 bg-white"
            >
              {statusList.map(({ id, name }: EntityItem) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>
    </section>
  );
};
