import { HistoryTable } from '../../../tables/HistoryTable';

export const UserHistoryTab: React.FC = () => (
  <div className="flex w-full flex-col flex-1 p-5">
    <p className="text-base text-gray-600 pb-5">Spent time history</p>
    <div className="h-full flex-1">
      <HistoryTable hiddenColumns={['author']} />
    </div>
  </div>
);
