import classNames from 'classnames';
import React, { ChangeEvent, MouseEvent } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { columnList } from './Data';
import { TasksTableRow } from '../../../lib/types/tables';

type TasksTableProps = {
  onItemClick: (value: string) => void;
  hiddenColumns?: (keyof TasksTableRow)[];
  isCheckboxVisible?: boolean;
};

export const TasksTable: React.FC<TasksTableProps> = ({
  onItemClick,
  hiddenColumns = ['id', 'comment', 'trackedTime', 'priority', 'timeSpent'],
  isCheckboxVisible = true,
}) => {
  const tasksTableData = useStore((state) => state.tasksTableData);
  const updateTasksTableData = useStore((state) => state.updateTasksTableData);
  const data = React.useMemo<TasksTableRow[]>(
    () => tasksTableData,
    [tasksTableData]
  );
  const columns = React.useMemo<Column<TasksTableRow>[]>(() => columnList, []);
  const initialState = { hiddenColumns };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  const onHeaderCheckboxClick = async (e: ChangeEvent<HTMLInputElement>) => {
    const newData = tasksTableData.map((task) => {
      return { ...task, isChecked: e.target.checked };
    });

    updateTasksTableData(newData);
  };
  const onCheckBoxClick = (id: string, isChecked: boolean) => {
    const newData = tasksTableData.map((task) => {
      if (id === task.id) {
        return { ...task, isChecked };
      }
      return task;
    });
    updateTasksTableData(newData);
  };

  return (
    <table {...getTableProps()} className="min-w-xs w-full text-xs">
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div className="flex justify-start items-center relative h-10">
                  {column.id === 'name' && isCheckboxVisible && (
                    <input
                      onChange={(e) => onHeaderCheckboxClick(e)}
                      type="checkbox"
                      className="w-3 h-3 rounded-xl mr-2"
                    />
                  )}
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[13px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[13px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="h-10 text-left border-b min-w-[100px] max-w-full"
              onClick={(event: MouseEvent) => {
                const target = event.target as Element;
                if (target.tagName !== 'INPUT' && target.tagName !== 'BUTTON') {
                  onItemClick(row.values.id);
                }
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4">
                    <div
                      className={classNames('flex items-center py-2', {
                        'justify-between px-4': cell.column.id === 'pl',
                      })}
                    >
                      {cell.value &&
                        cell.column.id === 'name' &&
                        isCheckboxVisible && (
                          <input
                            type="checkbox"
                            checked={cell.row.original.isChecked}
                            onChange={(event) =>
                              onCheckBoxClick(
                                row.values.id,
                                event.target.checked
                              )
                            }
                            className="w-3 h-3 rounded-xl mr-2"
                          />
                        )}
                      {cell.column.id === 'name' ||
                      cell.column.id === 'comment' ? (
                        <p className="overflow-hidden min-w-[200px]">
                          {cell.value}
                        </p>
                      ) : (
                        <p className="min-w-[100px]">{cell.render('Cell')}</p>
                      )}
                      {cell.value === '-' && cell.column.id === 'pl' && (
                        <button className="ml-2 text-blue-400 underline">
                          Add
                        </button>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
