import create, { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';
import {
  EntityItem,
  EntityListSlice,
  HistorySlice,
  ProjectsSlice,
  ReportFormSlice,
  ReportsSlice,
  REPORT_COMMENTS_TYPE,
  REPORT_TEMPLATE,
  TasksFoldersSlice,
  TasksSlice,
  TasksStoreItem,
  TeamsFoldersSlice,
  TeamsSlice,
  UsersSlice,
  UserProfile,
  UserProfileSlice,
} from '../types/store';
import {
  HistoryTableData,
  ProjectsTableData,
  ReportsTableData,
  TeamsTableData,
} from '../types/tables';

const userProfileSlice: StateCreator<
  UserProfileSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  UserProfileSlice
> = (set) => ({
  userProfile: {
    id: '',
  },
  updateUserProfile: (newData: UserProfile) =>
    set(() => ({ userProfile: newData })),
  cleanUserProfile: () =>
    set(() => ({
      userProfile: { id: '' },
    })),
});

const createUsersSlice: StateCreator<
  UsersSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  UsersSlice
> = (set) => ({
  usersTableData: [],
  updateUsersTableData: (newData: UserProfile[]) =>
    set(() => ({ usersTableData: newData })),
  cleanUsersTableData: () =>
    set(() => ({
      usersTableData: [],
    })),
});

const createTasksSlice: StateCreator<
  TasksSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  TasksSlice
> = (set) => ({
  tasksTableData: [],
  updateTasksTableData: (newData: TasksStoreItem[]) =>
    set(() => ({ tasksTableData: newData })),
  cleanTasksTableData: () =>
    set(() => ({
      tasksTableData: [],
    })),
});

const createProjectsSlice: StateCreator<
  ProjectsSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ProjectsSlice
> = (set) => ({
  projectsTableData: [],
  updateProjectsTableData: (newData: ProjectsTableData) =>
    set(() => ({ projectsTableData: newData })),
  cleanProjectsTableData: () =>
    set(() => ({
      projectsTableData: [],
    })),
});

const createReportsSlice: StateCreator<
  ReportsSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ReportsSlice
> = (set) => ({
  reportsTableData: [],
  updateReportsTableData: (newData: ReportsTableData) =>
    set(() => ({ reportsTableData: newData })),
  cleanReportsTableData: () =>
    set(() => ({
      reportsTableData: [],
    })),
});

const createTeamsSlice: StateCreator<
  TeamsSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  TeamsSlice
> = (set) => ({
  teamsTableData: [],
  updateTeamsTableData: (newData: TeamsTableData) =>
    set(() => ({ teamsTableData: newData })),
  cleanTeamsTableData: () =>
    set(() => ({
      teamsTableData: [],
    })),
});

const createTeamsFoldersSlice: StateCreator<
  TeamsFoldersSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  TeamsFoldersSlice
> = (set) => ({
  teamsFoldersTableData: [],
  updateTeamsFoldersTableData: (newData) =>
    set(() => ({ teamsFoldersTableData: newData })),
  cleanTeamsFoldersTableData: () =>
    set(() => ({
      teamsFoldersTableData: [],
    })),
});

const createTasksFoldersSlice: StateCreator<
  TasksFoldersSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  TasksFoldersSlice
> = (set) => ({
  tasksFoldersTableData: [],
  updateTasksFoldersTableData: (newData) =>
    set(() => ({ tasksFoldersTableData: newData })),
  cleanTasksFoldersTableData: () =>
    set(() => ({
      tasksFoldersTableData: [],
    })),
});

const createHistorySlice: StateCreator<
  HistorySlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  HistorySlice
> = (set) => ({
  historyTableData: [],
  updateHistoryTableData: (newData: HistoryTableData) =>
    set(() => ({ historyTableData: newData }), false, 'updateHistoryTableData'),
  cleanHistoryTableData: () =>
    set(
      () => ({
        historyTableData: [],
      }),
      false,
      'cleanHistoryTableData'
    ),
});

const createEntityListSlice: StateCreator<
  EntityListSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  EntityListSlice
> = (set, get) => ({
  // List for mocked entities. Remove when backend implement them.
  roleList: [
    { name: 'Admin', id: '1' },
    { name: 'PM', id: '2' },
    { name: 'Developer', id: '3' },
    { name: 'Designer', id: '4' },
  ],
  statusList: [
    { name: 'Working', id: '1' },
    { name: 'Vacation', id: '2' },
    { name: 'Paused', id: '3' },
  ],
  teamList: [
    { name: 'XM Team', id: '1' },
    { name: 'PK Team', id: '2' },
    { name: 'NY Team', id: '3' },
  ],
  positionList: [
    { name: 'Admin', id: '1' },
    { name: 'PM', id: '2' },
    { name: 'Developer', id: '3' },
    { name: 'Designer', id: '4' },
  ],
  pmList: [
    { name: 'Alex Andreev', id: '1' },
    { name: 'Max Davidov', id: '2' },
  ],
  clientList: [
    { name: 'John Green', id: '1', type: 'Type 1', email: 'email@admin.com' },
    {
      name: 'Victor Malcev',
      id: '2',
      type: 'Type 2',
      email: 'email@client.com',
    },
  ],
  clientTypeList: [
    { name: 'Type 1', id: '1' },
    { name: 'Type 2', id: '2' },
  ],
  getRoleById: (value: string) =>
    get().roleList.find((role: EntityItem) => role.id === value)?.name || null,
  getStatusById: (value: string) =>
    get().statusList.find((status: EntityItem) => status.id === value)?.name ||
    null,
  getTeamById: (value: string) =>
    get().teamList.find((team: EntityItem) => team.id === value)?.name || null,
  getPositionById: (value: string) =>
    get().positionList.find((position: EntityItem) => position.id === value)
      ?.name || null,
  getPmById: (value: string) =>
    get().pmList.find((position: EntityItem) => position.id === value)?.name ||
    null,
  getClientById: (value: string) =>
    get().clientList.find((position: EntityItem) => position.id === value) ||
    null,
  getClientTypeById: (value: string) =>
    get().clientList.find((position: EntityItem) => position.id === value)
      ?.name || null,
});

const createReportFormSlice: StateCreator<
  ReportFormSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ReportFormSlice
> = (set) => ({
  reportTemplate: REPORT_TEMPLATE.general,
  reportingPeriod: '',
  monthlyReportingPeriod: '',
  clientIdList: [],
  generalFormStep: 0,
  byLabelsFormStep: 0,
  byPeopleFormStep: 0,
  byTasksFormStep: 0,
  projectIdList: [],
  teamMemberList: [],
  commentsType: REPORT_COMMENTS_TYPE.byTask,
  resetAllFormsStep: () =>
    set(
      () => ({
        generalFormStep: 0,
        byLabelsFormStep: 0,
        byPeopleFormStep: 0,
        byTasksFormStep: 0,
      }),
      false,
      'resetAllFormsStep'
    ),
  updateFormStep: (newStep, template) =>
    set(
      () => {
        switch (template) {
          case REPORT_TEMPLATE.byLabels:
            return { byLabelsFormStep: newStep };
          case REPORT_TEMPLATE.byPeople:
            return { byPeopleFormStep: newStep };
          case REPORT_TEMPLATE.byTasks:
            return { byTasksFormStep: newStep };
          case REPORT_TEMPLATE.general:
            return { generalFormStep: newStep };
          default:
            return {};
        }
      },
      false,
      'updateFormStep'
    ),
  updateClientIdList: (newData) =>
    set(() => ({ clientIdList: newData }), false, 'updateClientIdList'),
  updateProjectIdList: (newData) =>
    set(() => ({ projectIdList: newData }), false, 'updateProjectIdList'),
  updateTeamMemberList: (newData) =>
    set(() => ({ teamMemberList: newData }), false, 'updateTeamMemberList'),
  updateReportingPeriod: (newPeriod) =>
    set(
      () => ({
        reportingPeriod: newPeriod,
      }),
      false,
      'updateReportingPeriod'
    ),
  updateMonthlyReportingPeriod: (newPeriod: String) =>
    set(
      () => ({
        monthlyReportingPeriod: newPeriod,
      }),
      false,
      'updateMonthlyReportingPeriod'
    ),
  updateReportTemplate: (newTemplate: REPORT_TEMPLATE) =>
    set(
      () => ({
        reportTemplate: newTemplate,
      }),
      false,
      'updateReportTemplate'
    ),
});

export const useStore = create<
  UsersSlice &
    TasksSlice &
    ProjectsSlice &
    EntityListSlice &
    TeamsSlice &
    ReportsSlice &
    HistorySlice &
    ReportFormSlice &
    TeamsFoldersSlice &
    TasksFoldersSlice &
    UserProfileSlice
>()(
  devtools(
    immer((...args) => ({
      ...userProfileSlice(...args),
      ...createUsersSlice(...args),
      ...createTasksSlice(...args),
      ...createProjectsSlice(...args),
      ...createReportsSlice(...args),
      ...createTeamsSlice(...args),
      ...createEntityListSlice(...args),
      ...createHistorySlice(...args),
      ...createReportFormSlice(...args),
      ...createTeamsFoldersSlice(...args),
      ...createTasksFoldersSlice(...args),
    }))
  )
);
