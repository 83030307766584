import formatDistance from 'date-fns/formatDistance';

const comments = [
  {
    id: '2vczx81',
    author: 'Ebrahim Iracli',
    date: '15:33 11.14.2022',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.',
  },
  {
    id: '2vczx82',
    author: 'Ebrahim Iracli',
    date: '15:33 11.13.2022',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.',
  },
  {
    id: '2vczx83',
    author: 'Ebrahim Iracli',
    date: '15:33 11.12.2022',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.',
  },
  {
    id: '2vczx84',
    author: 'Ebrahim Iracli',
    date: '15:33 10.12.2022',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.',
  },
  {
    id: '2vczx85',
    author: 'Ebrahim Iracli',
    date: '15:33 10.12.2022',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ullamcorper turpis fermentum tellus id arcu. Porttitor velit at nulla velit risus egestas. Purus sed a nisl massa.',
  },
];
type CommentListItem = {
  id: string;
  author: string;
  date: string;
  text: string;
};

export const TaskCommentsTab: React.FC = () => {
  const commentListItem = ({ id, author, date, text }: CommentListItem) => {
    return (
      <div
        className="flex flex-col pt-6 pb-4 border-b  border-gray-200"
        key={id}
      >
        <div className="flex items-center justify-between">
          <p className="text-gray-800 text-sm font-medium mb-1">{author}</p>
          <p className="text-gray-400 text-xs font-medium p-1">
            {formatDistance(new Date(date), new Date(), { addSuffix: true })}
          </p>
        </div>
        <div className="text-sm text-gray-500 bg-gray-100 p-1.5 rounded-md mb-3">
          {text}
        </div>
        <div className="flex text-xs text-gray-900 items-center">
          <p>Answer</p>
          <div className="h-2 w-2 rounded-full bg-gray-700 mx-2"></div>
          <p>Delete</p>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col px-5 min-h-[300px] max-h-[450px] overflow-hidden overflow-y-auto">
      <div className="p-2 w-full bg-gray-100 rounded-md">
        <span
          role="textbox"
          contentEditable
          placeholder="Add your comment..."
          className="block p-1 w-full bg-transparent focus:outline-0 max-h-20 overflow-idden after:text-gray-500 empty:after:content-[attr(placeholder)] after:w-10 after:h-4"
        ></span>
        <div className="flex justify-end">
          <button className="text-sm bg-black text-white px-4 py-2 rounded-lg">
            Add comment
          </button>
        </div>
      </div>
      {comments.map((comment) => commentListItem(comment))}
    </div>
  );
};
