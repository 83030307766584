import { VacationsTable } from '../../../tables/VacationsTable';

const mockedSummary: { [x: string]: string } = {
  period: '365 days',
  limit: '80 days',
  spent: '80 days',
  approved: '80 days',
};

export const UserVacationsTab: React.FC = () => {
  return (
    <div className="flex w-full flex-col flex-1 px-5 pt-8">
      <div className="w-full flex">
        <div className="w-2/5 text-base font-medium text-gray-600 pr-8">
          <p className="pb-2">Sick leave</p>
          <p className="text-sm text-gray-500">
            These will be notifications that will let you know when a report
            deadline is approaching.
          </p>
        </div>
        <div className="w-3/5 text-sm font-medium text-gray-600 pr-8 pb-8 ">
          <div className="border border-gray-100 p-2">SYMMARY</div>
          {Object.keys(mockedSummary).map((field) => {
            return (
              <div
                className="flex border border-gray-100 p-2 justify-between"
                key={field}
              >
                <p className="capitalize">{field}</p>
                <p>{mockedSummary[field]}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="h-full overflow-auto max-h-[300px]">
        <VacationsTable />
      </div>
    </div>
  );
};
