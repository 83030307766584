import { StatType } from '../../../../lib/utils/variables';

export type StatiticsMock = {
  title: string;
  modalTitle?: string;
  type: StatType;
  current: number;
  goal: number;
  isEditable?: boolean;
};

export const statiticsMock: StatiticsMock[] = [
  { title: 'Hours worked', type: StatType.APPROVED, current: 120, goal: 160 },
  { title: 'Posted', type: StatType.POSTED, current: 120, goal: 160 },
  {
    title: 'Sick leave used ',
    modalTitle: 'Seak leaves',
    type: StatType.SICKLEAVE,
    current: 0,
    goal: 40,
    isEditable: true,
  },
  {
    title: 'Vacation used',
    modalTitle: 'Vacations',
    type: StatType.VACATION,
    current: 10,
    goal: 80,
    isEditable: true,
  },
];