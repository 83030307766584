import React from 'react';
import { GeneralReportForm } from './GeneralReportForm';
import { ReportFormLayout } from '../../../layouts/ReportFormLayout';

export const GeneralReportPage: React.FC = () => {
  return (
    <ReportFormLayout>
      <GeneralReportForm />
    </ReportFormLayout>
  );
};
