import { HiOutlineCalendar } from 'react-icons/hi';
import { EntityItem } from '../../../../../lib/types/store';

type ChooseLabelsProps = {
  labelCount?: number;
};

export const mockLabelList = [
  { id: '1', name: 'Label #1' },
  { id: '2', name: 'Label #2' },
  { id: '3', name: 'Label #3' },
  { id: '4', name: 'Label #4' },
  { id: '5', name: 'Label #5' },
  { id: '6', name: 'Label #6' },
];

export const ChooseLabels: React.FC<ChooseLabelsProps> = ({
  labelCount = 1,
}) => {
  return (
    <>
      {Array(labelCount)
        .fill(null)
        .map((project, index) => (
          <div className="" key={project + index}>
            <label
              htmlFor={`Label${index + 1}`}
              className="text-sm text-gray-400"
            >
              Label {labelCount > 1 ? `#${index + 1}` : ''}
            </label>
            <select
              name={`Label${index + 1}`}
              className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
            >
              {mockLabelList.map(({ id, name }: EntityItem) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        ))}
      <div className="relative">
        <label htmlFor="reportingPeriod" className="text-sm text-gray-400">
          Reporting period
        </label>
        <input
          type="text"
          defaultValue="24.07.2022 - 25.08.2022"
          className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
        />
        <HiOutlineCalendar className="w-4 h-4 absolute bottom-4 right-2" />
      </div>
    </>
  );
};
