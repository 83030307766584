import {
  HiChevronDown,
} from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import logo from './../../../../public/logo.svg';

export const Navigation = () => {
  return (
    <div className="flex items-center text-sm">
      <img src={logo} alt="Custom Solutions" className="w-24 h-8 mr-20" />
      <div className="flex justify-between items-center">
        <NavLink
          to="/dashboard"
          className="flex items-center px-1 py-0.5 rounded"
        >
          <p className="p-2 w-full whitespace-nowrap overflow-hidden ">
            Dashboard
          </p>
        </NavLink>
        <NavLink to="/tasks" className="flex items-center px-1 py-0.5 rounded">
          <p className="p-2 w-full whitespace-nowrap overflow-hidden ">Tasks</p>
        </NavLink>
        <NavLink to="/users" className="flex items-center px-1 py-0.5 rounded">
          <p className="p-2 w-full whitespace-nowrap overflow-hidden">Users</p>
        </NavLink>
        <NavLink
          to="/projects"
          className="flex items-center px-1 py-0.5 rounded"
        >
          <p className="p-1 w-full whitespace-nowrap overflow-hidden">
            Projects
          </p>
          <HiChevronDown className="w-5 h-5" />
        </NavLink>
      </div>
    </div>
  );
};
