export const notificationVariants = [
  'Reports',
  'Projects',
  'Sick leave',
  'Vacations',
];

export enum StatType {
  APPROVED = 'approvedHours',
  POSTED = 'postedHours',
  SICKLEAVE = 'sickLeaveHours',
  VACATION = 'vacationHours',
}

export enum NotificatonOptions {
  push = 'PUSH',
  email = 'EMAIL',
  telegram = 'TELEGRAM',
}

export const shortDateFormat = 'dd.MM.yyyy';
export const shortMonthDateFormat = 'd MMM yyyy';
