import format from 'date-fns/format';
import React, { useState } from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';
import { shortDateFormat } from '../../../lib/utils/variables';
import { SingleDatePicker } from '../SingleDatePicker';

type SingleDateInputProps = {
  onDateChange: (date: string) => void;
};

export const SingleDateInput = React.forwardRef<
  HTMLInputElement,
  SingleDateInputProps
>(({ onDateChange, ...restProps }, ref) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState('01.01.2023');

  const onPickerVisibleChange = () => {
    setIsPickerVisible(() => !isPickerVisible);
  };

  const onDateChangeFormatting = (date: Date) => {
    onPickerVisibleChange();

    if (date) {
      const formattedDate = format(date, shortDateFormat);

      setCurrentDate(formattedDate);
      return onDateChange(formattedDate);
    }
  };

  return (
    <div className="relative">
      <input
        ref={ref}
        value={currentDate}
        onClick={() => onPickerVisibleChange()}
        type="text"
        defaultValue="01.01.2023"
        className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700"
        {...restProps}
      />
      <HiOutlineCalendar
        className="w-4 h-4 absolute top-5 right-2"
        onClick={() => onPickerVisibleChange()}
      />
      {isPickerVisible && (
        <div className="absolute w-[200px] h-[200px] border">
          <SingleDatePicker onDateChange={onDateChangeFormatting} />
        </div>
      )}
    </div>
  );
});
