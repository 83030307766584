import React from 'react';
import { useForm } from 'react-hook-form';
import { TasksStoreItem } from '../../../../lib/types/store';
import { SearchBar } from '../../../common/SearchBar';
import { TeamsTable } from '../../../tables/TeamsTable';

export const CreateTeamForm: React.FC = () => {
  const { register, handleSubmit } = useForm<TasksStoreItem>({
    mode: 'onChange',
  });

  const onSubmit = (data: TasksStoreItem) => {
    // TODO : adding team when endpoint will be ready
  };

  return (
    <div className="h-full max-w-[400px] mx-auto shadow bg-white px-12 py-10">
      <p className="font-md text-xl text-gray-800 pb-6">Add team</p>
      <form className="flex flex-col">
        <div className="mb-8 space-y-4">
          <div className="">
            <label htmlFor="name" className="text-sm text-gray-400">
              Name of team
            </label>
            <input
              placeholder="Team Name"
              {...register('name')}
              className="w-full rounded p-2 border-gray-100 border-2 bg-white mt-1"
            />
          </div>
          <SearchBar className="block w-full p-2 pl-10 text-sm text-gray-900 rounded-lg border border-gray-100" />
          <TeamsTable
            onCheckBoxClick={() => {}}
            containerClassName="border-0"
            hiddenColumns={[
              'id',
              'latestUpdate',
              'tasks',
              'spentTime',
              'trackedTime',
            ]}
          />
        </div>
      </form>
      <div className="w-full">
        <button
          className="bg-blue-500 text-white p-2 rounded w-full"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </button>
        <button
          type="button"
          className="bg-white text-blue-500 p-2 rounded w-full"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
