import React from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../../lib/store';
import { TasksStoreItem } from '../../../../lib/types/store';

export const CreateTaskForm: React.FC = () => {
  const { tasksTableData, updateTasksTableData } = useStore((state) => state);
  const { register, handleSubmit } = useForm<TasksStoreItem>({
    defaultValues: {
      projectId: '1',
    },
    mode: 'onChange',
  });

  const onSubmit = (data: TasksStoreItem) => {
    updateTasksTableData([
      ...tasksTableData,
      {
        ...data,
        id: String(Math.round(Math.random() * 1000)),
      },
    ]);
  };

  return (
    <div className="h-full max-w-[400px] mx-auto shadow bg-white px-12 py-10">
      <p className="font-md text-xl text-gray-800 pb-6">Add User</p>
      <form className="flex flex-col">
        <div className="mb-8 space-y-4">
          <div className="">
            <label htmlFor="link" className="text-sm text-gray-400">
              Jira or Trello Link
            </label>
            <input
              {...register('link')}
              placeholder="Jira or Trello Link"
              className="w-full rounded p-2 border-gray-200 border bg-white"
            />
          </div>
          <div className="">
            <label htmlFor="name" className="text-sm text-gray-400">
              Name *
            </label>
            <input
              {...register('name')}
              placeholder="Task Name"
              className="w-full rounded p-2 border-gray-200 border bg-white"
            />
          </div>
          <div className="flex items-center">
            <input type="checkbox" className="w-4 h-4 rounded mr-2" id="pl" />
            <label htmlFor="pl" className="text-sm text-gray-600">
              Add to PL
            </label>
          </div>
          <div className="">
            <label htmlFor="plannedTime" className="text-sm text-gray-400">
              Estimated time
            </label>
            <input
              {...register('plannedTime')}
              placeholder="10"
              className="w-full rounded p-2 border-gray-200 border bg-white"
            />
          </div>
        </div>
      </form>
      <div className="w-full">
        <button
          className="bg-blue-500 text-white p-2 rounded w-full"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </button>
        <button
          type="button"
          className="bg-white text-blue-500 p-2 rounded w-full"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
