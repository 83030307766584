import { Column } from 'react-table';
import { TeamsFoldersTableRow } from '../../../lib/types/tables';

export const columnList: Column<TeamsFoldersTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'TEAM',
    accessor: 'teamName',
  },
  {
    Header: 'NUMBER OF PEOPLE',
    accessor: 'membersCount',
  },
];

export const TableData: TeamsFoldersTableRow[] = Array(10).fill({
  id: 'mlkvasd8',
  teamName: 'Egor`s team',
  membersCount: '12',
});
