type ToastPopupUIProps = {
  title?: string;
  message?: string;
  onClose?: () => void;
};

export const ToastPopupUI: (props: ToastPopupUIProps) => React.ReactNode = ({
  title,
  message,
}) => {
  return (
    <div className="px-1 flex-1">
      <p className="text-sm font-medium text-gray-700">{title || 'Title'}</p>
      <p className="text-xs text-gray-500">{message || 'message'}</p>
    </div>
  );
};
