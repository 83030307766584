import React from 'react';
import { AdminFormLayout } from '../../layouts/FormLayout';
import { CreateProjectForm } from './CreateProjectForm';

export const CreateProjectPage: React.FC = () => {
  return (
    <AdminFormLayout>
      <CreateProjectForm />
    </AdminFormLayout>
  );
};
