import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../../../lib/styles/customDatepickerStyles.css';

type DateRangePickerProps = {
  onDateChange: (date: [Date | null, Date | null]) => void;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onDateChange,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      onDateChange(dates);
    }
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      dateFormat="dd/mm/yyyy"
      startDate={startDate}
      endDate={endDate}
      calendarClassName="absolute"
      selectsRange
      inline
    />
  );
};
