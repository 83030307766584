import { DateRangePicker } from '../../common/DateRangePicker';

export type DataPickerModalProps = {
  readonly isModalVisible: boolean;
  readonly onSubmit: () => void;
  readonly onClose: () => void;
};

export const DataPickerModal: React.FC<DataPickerModalProps> = ({
  isModalVisible,
  onSubmit,
  onClose,
}) => {
  return isModalVisible ? (
    <div className="absolute z-10 w-[300px] h-[380px] bg-white shadow-md flex flex-col justify-between p-6">
      <div className="relative">
        <p className="text-md text-gray-900 pb-6 font-semibold">Select dates</p>
        <DateRangePicker onDateChange={onSubmit} />
      </div>
      <div className="w-full flex justify-end text-xs">
        <button
          type="button"
          className="bg-white text-blue-500 px-3 py-1.5 rounded"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-3 py-1.5 rounded"
          onClick={() => onClose()}
        >
          Apply vacation
        </button>
      </div>
    </div>
  ) : null;
};
