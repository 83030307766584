import { Column } from 'react-table';
import { UsersTableRow } from '../../../lib/types/tables';

export const columnList: Column<UsersTableRow>[] = [
  {
    Header: 'PL ID',
    accessor: 'id', // accessor is the "key" in the data
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'TEAM',
    accessor: 'teamId',
  },
  {
    Header: 'ROLE',
    accessor: 'roleId',
  },
  {
    Header: 'POSITION',
    accessor: 'positionId',
  },
  {
    Header: 'STATUS',
    accessor: 'statusId',
  },
];

export const TableData = [
  {
    id: '4',
    name: 'Scarlet Taylor',
    teamId: '1',
    roleId: '1',
    positionId: '1',
    statusId: '1',
  },
  {
    id: '5',
    name: 'Scarlet Taylor',
    teamId: '1',
    roleId: '1',
    positionId: '1',
    statusId: '1',
  },
  {
    id: '52',
    name: 'Scarlet Taylor',
    teamId: '1',
    roleId: '2',
    positionId: '3',
    statusId: '3',
  },
  {
    id: '51',
    name: 'Scarlet Taylor',
    teamId: '1',
    roleId: '2',
    positionId: '2',
    statusId: '2',
  },
  {
    id: '59',
    name: 'Scarlet Taylor',
    teamId: '1',
    roleId: '3',
    positionId: '3',
    statusId: '1',
  },
  {
    id: '65',
    name: 'Scarlet Taylor',
    teamId: '1',
    roleId: '2',
    positionId: '3',
    statusId: '1',
  },
  {
    id: '35',
    name: 'Scarlet Taylor',
    teamId: '1',
    roleId: '3',
    positionId: '1',
    statusId: '1',
  },
];
