import classNames from 'classnames';
import { useMemo, useState } from 'react';
import {
  HiArrowLeft,
  HiChevronDown,
  HiOutlineCloudDownload,
  HiOutlineTrash,
} from 'react-icons/hi';
import { useStore } from '../../../lib/store';
import { TasksStoreItem } from '../../../lib/types/store';
import { TaskCommentsTab } from '../ModalTabsTemplates/TaskCommentsTab';
import { TaskHistoryTab } from '../ModalTabsTemplates/TaskHistoryTab';
import { TaskLinksTab } from '../ModalTabsTemplates/TaskLinksTab';

export type TaskInfoModalProps = {
  readonly taskId: string;
  readonly title?: string;
  readonly closeModal: () => void;
};
enum TaskTabs {
  comments = 'COMMENTS',
  history = 'HISTORY',
  links = 'LINKS',
}

const infoFields: { label: string; value: keyof TasksStoreItem }[] = [
  { label: 'Start date', value: 'startDate' },
  { label: 'Related project', value: 'projectId' },
  { label: 'Already entered time', value: 'enteredTime' },
];

export const TaskInfoModal: React.FC<TaskInfoModalProps> = ({
  taskId,
  closeModal,
}) => {
  const tasksTableData = useStore((state) => state.tasksTableData);
  const task = useMemo(
    () => tasksTableData.find((task) => task.id === taskId),
    [tasksTableData, taskId]
  );
  const [currentTab, setCurrentTab] = useState<TaskTabs>(TaskTabs.comments);

  return (
    <div className="flex flex-col h-full flex-1">
      <div className="flex justify-between items-start p-4 rounded-t relative  px-5">
        <h3 className="mt-20 mb-6 max-h-[60px] overflow-y-hidden text-xl font-medium text-gray-900">
          {task?.name}
        </h3>
        <button
          type="button"
          className="absolute left-3 top-8 text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={() => closeModal()}
        >
          <HiArrowLeft className="w-5 h-5 " />
        </button>
        <div className="absolute top-8 right-3 flex">
          <button
            type="button"
            className=" text-gray-800 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          >
            <HiOutlineCloudDownload className="w-5 h-5 " />
          </button>
          <button
            type="button"
            className=" text-gray-800 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          >
            <HiOutlineTrash className="w-5 h-5 " />
          </button>
        </div>
      </div>
      <div className="flex flex-col px-5">
        {infoFields.map((fieldName) => {
          if (task) {
            return (
              <div className="flex text-sm w-full mb-3 items-center">
                <div className="text-gray-500 w-36 ">{fieldName.label}</div>
                <div className="text-gray-800 py-1 px-2">
                  {task[fieldName.value] || 'Empty'}
                </div>
              </div>
            );
          }
          return null;
        })}
        <div className="flex text-sm w-full mb-3 items-center">
          <div className="text-gray-500 w-36">Task Type</div>
          <div className="px-2 py-1">Default</div>
          <HiChevronDown className="h-5 w-5 ml-2" />
        </div>
        <div className="flex text-sm w-full mb-6 items-center">
          <div className="text-gray-500 w-36">Status</div>
          <div className="text-orange-500 bg-orange-100 rounded-xl px-2 py-1">
            {task?.status || 'Not Active'}
          </div>
          <HiChevronDown className="h-5 w-5 ml-2" />
        </div>
      </div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
        <ul className="flex -mb-p text-sm">
          <li className="ml-2">
            <button
              onClick={() => setCurrentTab(TaskTabs.links)}
              className={classNames(
                'inline-block p-3 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                { 'text-black border-black': currentTab === TaskTabs.links }
              )}
            >
              Info
            </button>
          </li>
          <li className="ml-2">
            <button
              onClick={() => setCurrentTab(TaskTabs.comments)}
              className={classNames(
                'inline-block p-3 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                { 'text-black border-black': currentTab === TaskTabs.comments }
              )}
            >
              Comments
            </button>
          </li>
          <li className="ml-2">
            <button
              onClick={() => setCurrentTab(TaskTabs.history)}
              className={classNames(
                'inline-block p-3 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                { 'text-black border-black': currentTab === TaskTabs.history }
              )}
            >
              History
            </button>
          </li>
        </ul>
      </div>
      <div className="flex-1 py-5">
        {currentTab === TaskTabs.comments && <TaskCommentsTab />}
        {currentTab === TaskTabs.history && <TaskHistoryTab />}
        {currentTab === TaskTabs.links && <TaskLinksTab />}
      </div>
      <div className="absolute bottom-1 left-0 right-0 bg-gray-100 border-t h-16 flex justify-end items-center px-4">
        <button type="button" className="bg-blue-500 text-white p-2 rounded">
          Enter the time
        </button>
      </div>
    </div>
  );
};
