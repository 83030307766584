import { HiLink } from 'react-icons/hi';

export const TaskLinksTab: React.FC = () => (
  <div className="w-full flex border-b border-gray-200 px-5 py-6">
    <div className="w-2/3 pr-8">
      <p className="text-base font-medium text-gray-600">Links</p>
      <p className="text-sm text-gray-500">
        These will be notifications that will let you know when a report
        deadline is approaching.
      </p>
    </div>
    <div className="w-full">
      <p className="text-sm text-gray-400 pb-1.5">Jira</p>
      <div className=" text-gray-800 font-medium text-base flex items-center">
        <p className="max-w-[350px] whitespace-nowrap overflow-hidden text-ellipsis">
          {' '}
          https://xpertyme.atlassian.net/jira/software/c/pro..{' '}
        </p>
        <HiLink className="ml-2" />
      </div>
    </div>
  </div>
);
