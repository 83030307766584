import { Column } from 'react-table';
import { TeamsTableRow } from '../../../lib/types/tables';

export const columnList: Column<TeamsTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'ROLE',
    accessor: 'roleId',
  },
  {
    Header: 'TASKS',
    accessor: 'tasks',
  },
  {
    Header: 'TRACKED TIME',
    accessor: 'trackedTime',
  },
  {
    Header: 'LATEST UPDATE',
    accessor: 'latestUpdate',
  },
  {
    Header: 'SPENT TIME',
    accessor: 'spentTime',
  },
];

export const TableData = [
  {
    id: 'mlkvasd8',
    name: 'Alexander Andreev',
    roleId: '2',
    trackedTime: '120/160',
    latestUpdate: '01.01.2022',
    spentTime: '120',
    tasks: [
      {
        id: '23zds4xad',
      },
      {
        id: '2gf3sgvcdrd',
      },
      {
        id: '32x23dsad',
      },
    ],
  },
  {
    id: 'dsdxv5y6353yg',
    name: 'Alexander Andreev',
    roleId: '3',
    trackedTime: '120/160',
    latestUpdate: '01.01.2022',
    spentTime: '120',
    tasks: [
      {
        id: '23zds4xad',
      },
      {
        id: '2gf3sgvcdrd',
      },
      {
        id: '32x23dsad',
      },
    ],
  },
  {
    id: 'sdacSccsd89v',
    name: 'Alexander Andreev',
    roleId: '3',
    trackedTime: '120/160',
    latestUpdate: '01.01.2022',
    spentTime: '120',
    tasks: [
      {
        id: '23zds4xad',
      },
      {
        id: '2gf3sgvcdrd',
      },
      {
        id: '32x23dsad',
      },
    ],
  },
  {
    id: 'DSVkfsc9',
    name: 'Alexander Andreev',
    roleId: '3',
    trackedTime: '120/160',
    latestUpdate: '01.01.2022',
    spentTime: '120',
    tasks: [
      {
        id: '23zds4xad',
      },
      {
        id: '2gf3sgvcdrd',
      },
      {
        id: '32x23dsad',
      },
    ],
  },
];
