import { useForm } from 'react-hook-form';
import { useStore } from '../../../lib/store';
import { SingleDateInput } from '../../common/SingleDateInput';
import { Portal } from '../Modal/Portal';

type TrackedTimeForm = {
  projectId: string;
  taskId: string;
  startDate: string;
  endDate: string;
  timeSpent: string;
  includeWeekends: boolean;
  description: string;
};

export type TrackedTimeModalProps = {
  readonly isModalVisible: boolean;
  readonly onClose: () => void;
};

export const TrackedTimeModal: React.FC<TrackedTimeModalProps> = ({
  isModalVisible,
  onClose,
}) => {
  const { register, setValue } =
    useForm<TrackedTimeForm>({
      mode: 'onChange',
      defaultValues: {
        projectId: '',
        taskId: '',
        startDate: '24.07.2022',
        endDate: '',
        timeSpent: '',
        includeWeekends: false,
        description: '',
      },
    });

  const tasksTableData = useStore((state) => state.tasksTableData);
  const projectsTableData = useStore((state) => state.projectsTableData);

  return isModalVisible ? (
    <Portal>
      <div className="absolute inset-0 z-20 bg-[rgba(0,0,0,60%)] shadow-md flex flex-col justify-center items-center p-6">
        <div className="w-[700px] h-[550px] bg-white p-10 rounded">
          <p className="text-xl font-medium pb-6">Add tracked time</p>
          <form>
            <div className="w-full flex gap-5 pb-5">
              <div className="w-1/2">
                <label className="text-sm text-gray-400" htmlFor="projectId">
                  Project
                </label>
                <select
                  {...register('projectId')}
                  placeholder="Choose project"
                  className="w-full rounded p-2 mt-1.5 bg-white border-gray-200 border-2"
                >
                  {projectsTableData.map(({ name }) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                <label className="text-sm text-gray-400" htmlFor="taskId">
                  Task
                </label>
                <select
                  {...register('taskId')}
                  defaultValue="Choose task"
                  className="w-full rounded p-2 mt-1.5 bg-white border-gray-200 border-2"
                >
                  {tasksTableData.map(({ name }) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex w-full gap-5">
              <div className="w-1/4 relative">
                <label className="text-sm text-gray-400" htmlFor="startDate">
                  Starting date *
                </label>
                <SingleDateInput
                  {...register('startDate')}
                  onDateChange={(date: string) => {
                    setValue('startDate', date);
                  }}
                />
              </div>
              <div className="w-1/4 relative">
                <label className="text-sm text-gray-400" htmlFor="endDate">
                  Starting date *
                </label>
                <SingleDateInput
                  {...register('endDate')}
                  onDateChange={(date: string) => {
                    setValue('endDate', date);
                  }}
                />
              </div>
              <div className="w-1/4">
                <label htmlFor="emailTitle" className="text-sm text-gray-400">
                  Time spent
                </label>
                <input
                  defaultValue="Value"
                  {...register('timeSpent')}
                  className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
                />
              </div>
              <div className="flex items-end pt-2 w-1/4">
                <input
                  type="checkbox"
                  {...register('includeWeekends')}
                  className="h-4 w-4 rounded mr-2 border-gray-400 "
                />
                <label
                  htmlFor="isReportForPeriod"
                  className="text-gray-500 text-sm"
                >
                  Incl. wknds.
                </label>
              </div>
            </div>
            <p className="text-sm text-gray-800 pb-5 pt-2">
              * The 10:30 hours of reporting will be entered.
            </p>
            <div className="">
              <label htmlFor="description" className="text-sm text-gray-400">
                Description
              </label>
              <textarea
                placeholder="Lorem ipsum dolor sit amet consectetur. Pellentesque egestas et at in turpis."
                {...register('description')}
                className="w-full h-20 rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
              />
            </div>
            <div className="w-full flex justify-end pt-12">
              <button
                type="button"
                onClick={() => onClose()}
                className="bg-white text-blue-500 p-2 rounded w-36"
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white p-2 rounded w-36"
                onClick={() => onClose()}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </Portal>
  ) : null;
};
