import { UseFormRegister } from 'react-hook-form';
import { useStore } from '../../../../../lib/store';
import { ClientEmailReportForm } from '../../GeneralReportPage/GeneralReportForm';

type CustomerInfoProps = {
  register: UseFormRegister<ClientEmailReportForm>;
};

export const CustomerInfo: React.FC<CustomerInfoProps> = ({ register }) => {
  // TODO : change on selected client id (List) when endpoint will be ready
  const clientList = useStore((state) => state.clientList);

  return (
    <form className="space-y-6">
      <div className="">
        <label htmlFor="email" className="text-sm text-gray-400">
          Client email
        </label>
        <select
          {...register('email')}
          className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
        >
          {clientList.map((client) => (
            <option key={client.id} value={client.email}>
              {client.email}
            </option>
          ))}
        </select>
      </div>
      <div className="">
        <label htmlFor="emailTitle" className="text-sm text-gray-400">
          Title of email
        </label>
        <input
          defaultValue="Value"
          {...register('emailTitle')}
          className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
        />
        <div className="flex items-center pt-2">
          <input
            type="checkbox"
            {...register('isReportForPeriod')}
            className="h-4 w-4 rounded mr-4 border-gray-400 "
          />
          <label htmlFor="isReportForPeriod" className="text-gray-500">
            Sign "Report for (period)"
          </label>
        </div>
      </div>
      <div className="">
        <label htmlFor="emailBody" className="text-sm text-gray-400">
          Email`s body
        </label>
        <textarea
          placeholder="Text"
          {...register('emailBody')}
          className="w-full h-40 rounded p-2 mt-1.5 border-gray-200 border-2 bg-white"
        />
      </div>
    </form>
  );
};
