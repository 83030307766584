import classNames from 'classnames';
import React, { useState } from 'react';
import { isToday, isYesterday } from 'date-fns';

import { RecordMessageItem } from './RecordMessageItem';
import { sortByDateDescending } from '../../../../lib/utils/sortByDate';
import { messagesMock, recordsTabs } from './Mocks';

export const Records: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(recordsTabs.ALL);
  const messages = messagesMock.sort((a, b) =>
    sortByDateDescending(a.createdAt, b.createdAt)
  );
  const todayMessages = messages.filter((message) =>
    isToday(message.createdAt)
  );
  const yesterdayMessages = messages.filter((message) =>
    isYesterday(message.createdAt)
  );
  const onTabChange = (tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <div className="">
      <div id="tabs" className="flex space-x-1 bg-white overflow-y-visible">
        <ul className="flex flex-wrap -mb-p text-sm ml-2">
          {Object.values(recordsTabs).map((tab) => (
            <li>
              <button
                onClick={() => onTabChange(tab)}
                className={classNames(
                  'inline-block mx-2 mt-2 pb-2 rounded-t-lg border-b-2 lowercase first-letter:capitalize border-transparent hover:text-gray-600 hover:border-gray-900',
                  {
                    'text-black border-black': currentTab === recordsTabs.tab,
                  }
                )}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div id="wheel" className="h-[700px] overflow-auto pb-2">
        <div className="bg-gray-100 text-xs text-gray-500 py-2">Today</div>
        {todayMessages.map((message) => (
          <RecordMessageItem message={message} />
        ))}
        <div className="bg-gray-100 text-xs text-gray-500 py-2">Yesterday</div>
        {yesterdayMessages.map((message) => (
          <RecordMessageItem message={message} />
        ))}
      </div>
    </div>
  );
};
