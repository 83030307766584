import { format } from 'date-fns';
import { RecordMessage } from '../../../../../lib/types/entities';
import { getDatePrefix } from '../../../../../lib/utils/getDatePrefix';
import { shortMonthDateFormat } from '../../../../../lib/utils/variables';

type RecordMessageItemProps = {
  message: RecordMessage;
};

export const RecordMessageItem: React.FC<RecordMessageItemProps> = ({
  message,
}) => (
  <div className="bg-white w-full flex p-4">
    <div className="space-y-2">
      <div>{message.title}</div>
      {message?.info && (
        <div className="rounded bg-orange-50 text-gray-500 py-1 px-2">
          {message.info}
        </div>
      )}
      {message.info && (
        <div className="bg-white rounded-md border border-gray-500 text-center  max-w-[110px] text-gray-500 py-1 px-2">
          {format(message.createdAt, shortMonthDateFormat)}
        </div>
      )}
      {message.isTrackTimeReminder && (
        <div className="bg-blue-500 px-2 py-1 rounded-md text-center max-w-[130px] text-white">
          Track the time
        </div>
      )}
    </div>
    <div className="text-gray-400 min-w-[60px] text-center">
      {getDatePrefix(message.createdAt)}
    </div>
  </div>
);
