import { useState } from 'react';
import { StatType } from '../../../../lib/utils/variables';
import { DashboardStatiscticsItem } from './DashboardStatiscticsItem';
import { statiticsMock } from './Mock';

export enum StatModalOption {
  INFO = 'INFO',
  DATE = 'DATE',
}

export const DashboardStatistics = () => {
  const [isVacationDatePickerVisible, setIsVacationDatePickerVisible] =
    useState(false);
  const [isSickleaveDatePickerVisible, setIsSickleaveDatePickerVisible] =
    useState(false);
  const [isVacationInfoVisible, setIsVacationInfoVisible] = useState(false);
  const [isSickleaveInfoVisible, setIsSickleaveInfoVisible] = useState(false);

  const onModalVisabilityChange = ({
    type,
    modalOption,
    value,
  }: {
    type: StatType;
    modalOption: StatModalOption;
    value: boolean;
  }) => {
    if (type === StatType.SICKLEAVE) {
      return modalOption === StatModalOption.INFO
        ? setIsSickleaveInfoVisible(value)
        : setIsSickleaveDatePickerVisible(value);
    }
    if (type === StatType.VACATION) {
      return modalOption === StatModalOption.INFO
        ? setIsVacationInfoVisible(value)
        : setIsVacationDatePickerVisible(value);
    }
    return;
  };

  const isModalVisible = (type: StatType, modalOption: StatModalOption) => {
    if (type === StatType.SICKLEAVE) {
      return modalOption === StatModalOption.INFO
        ? isSickleaveInfoVisible
        : isSickleaveDatePickerVisible;
    }
    if (type === StatType.VACATION) {
      return modalOption === StatModalOption.INFO
        ? isVacationInfoVisible
        : isVacationDatePickerVisible;
    }
    return false;
  };

  return (
    <div id="statistics" className="flex flex-col ">
      <p className="text-xl font-medium p-5">Statistics</p>
      <div className="flex justify-between">
        {statiticsMock.map((stat) => (
          <DashboardStatiscticsItem
            stat={stat}
            isModalVisible={isModalVisible}
            onModalVisabilityChange={onModalVisabilityChange}
          />
        ))}
      </div>
    </div>
  );
};
