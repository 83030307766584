import { Column } from 'react-table';
import { TasksTableRow } from '../../../lib/types/tables';

export const columnList: Column<TasksTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'TASK NAME',
    accessor: 'name',
  },
  {
    Header: 'PROJECT',
    accessor: 'projectId',
    maxWidth: 150,
  },
  {
    Header: 'IN PL',
    accessor: 'pl',
    maxWidth: 120,
  },
  {
    Header: 'PRIORITY',
    accessor: 'priority',
  },
  {
    Header: 'TIME SPENT',
    accessor: 'timeSpent',
  },
  {
    Header: 'TRACED TIME',
    accessor: 'trackedTime',
  },
  {
    Header: 'COMMENT',
    accessor: 'comment',
  },
];

export const TableData = [
  {
    name: 'XPERNG-7272 Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary,Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary,Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary',
    projectId: 'Project name',
    pl: '-',
    isChecked: false,
    id: '23zds4xad',
    trackedTime: '3',
    priority: 'NORMAL',
    timeSpent: '3:00',
    comment: 'Lorem ipsum',
  },
  {
    name: 'XPERNG-7272 Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary',
    projectId: 'Project name',
    pl: '+',
    isChecked: false,
    id: '2gf3sgvcdrd',
    trackedTime: '3',
    priority: 'NORMAL',
    timeSpent: '3:00',
    comment: 'Lorem ipsum',
  },
  {
    name: 'XPERNG-7272 Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary',
    projectId: 'Project name',
    pl: '-',
    isChecked: false,
    id: '32x23dsad',
    trackedTime: '3',
    priority: 'NORMAL',
    timeSpent: '3:00',
    comment: 'Lorem ipsum',
  },
  {
    name: 'XPERNG-7272 Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary',
    projectId: 'Project name',
    pl: '-',
    isChecked: false,
    id: '2x3dsxxxxad',
    trackedTime: '3',
    priority: 'NORMAL',
    timeSpent: '3:00',
    comment: 'Lorem ipsum',
  },
  {
    name: 'XPERNG-7272 Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary',
    projectId: 'Project name',
    pl: '-',
    isChecked: false,
    id: '2v3dsazxd',
    trackedTime: '3',
    priority: 'NORMAL',
    timeSpent: '3:00',
    comment: 'Lorem ipsum',
  },
  {
    name: 'XPERNG-7272 Subscriptionplan: if user selects free subscription plan than, than NO payment details are necessary',
    projectId: 'Project name',
    pl: '-',
    isChecked: false,
    id: '2z3fd3dssdad',
    trackedTime: '3',
    priority: 'NORMAL',
    timeSpent: '3:00',
    comment: 'Lorem ipsum',
  },
];
