import { InputHTMLAttributes } from 'react';
import { HiSearch } from 'react-icons/hi';

type SearchBarProps = {
  iconClassName?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const SearchBar: React.FC<SearchBarProps> = ({
  iconClassName = 'w-5 h-5 fill-gray-400',
  ...restProps
}) => {
  return (
    <form className="flex items-center relative w-full">
      <div className="absolute left-3 pointer-events-none w-5 h-5">
        <HiSearch className={iconClassName} />
      </div>
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only"
      >
        Search
      </label>
      <input
        type="search"
        id="default-search"
        placeholder="Search"
        {...restProps}
      />
    </form>
  );
};
