import { Column } from 'react-table';
import { HistoryTableRow } from '../../../lib/types/tables';

export const columnList: Column<HistoryTableRow>[] = [
  {
    Header: 'AUTHOR',
    accessor: 'author',
  },
  {
    Header: 'TASK',
    accessor: 'task',
  },
  {
    Header: 'HOURS SPENT',
    accessor: 'hoursSpent',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
];

export const TableData: HistoryTableRow[] = [
  {
    author: 'Alexander Andreev',
    hoursSpent: '12',
    date: '12.12.2022',
    status: 'In Process',
    task: 'XPERNG-722472 Subscriptionplan: if user selects ...',
  },
  {
    author: 'Alexander Andreev',
    hoursSpent: '12',
    date: '12.12.2022',
    status: 'In Process',
    task: 'XPERNG-72s72 Subscriptionplan: if user selects ...',
  },
  {
    author: 'Alexander Andreev',
    hoursSpent: '12',
    date: '12.12.2022',
    status: 'In Process',
    task: 'XPERNG-727d2 Subscriptionplan: if user selects ...',
  },
  {
    author: 'Alexander Andreev',
    hoursSpent: '12',
    date: '12.12.2022',
    status: 'In Process',
    task: 'XPERNG-71272 Subscriptionplan: if user selects ...',
  },
];
