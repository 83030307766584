import classNames from 'classnames';
import React from 'react';
import { useStore } from '../../../../../lib/store';

const mockSteps = ['Report', 'Period', 'Tasks', 'Users', 'Client'];

type ReportFormStepBarProps = {
  steps: string[];
  activeStep?: number;
};

export const ReportFormStepBar: React.FC<ReportFormStepBarProps> = ({
  steps = mockSteps,
  activeStep = 0,
}) => {
  const updateFormStep = useStore((state) => state.updateFormStep);
  const reportTemplate = useStore((state) => state.reportTemplate);

  const onStepClick = (index: number) => {
    if (index > 0) {
      updateFormStep(index, reportTemplate);
    } else {
      window.location.pathname = '/admin/createReport';
    }
  };

  return (
    <div className="w-full mb-8 flex space-x-2 justify-between">
      {steps.map((stepTitle, index) => {
        return (
          <div
            key={stepTitle}
            className="w-full flex flex-col justify-end"
            onClick={() => activeStep !== 0 && onStepClick(index)}
          >
            <p
              className={classNames('text-xs py-1.5 text-center', {
                'text-gray-800': activeStep > index,
                'text-gray-400': activeStep < index,
                'text-blue-500': activeStep === index,
              })}
            >
              {stepTitle}
            </p>
            <div
              className={classNames('h-2 w-full rounded-xl', {
                'bg-blue-400': activeStep >= index,
                'bg-gray-400': activeStep < index,
              })}
            />
          </div>
        );
      })}
    </div>
  );
};
