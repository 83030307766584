import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal: React.FC<PropsWithChildren> = ({ children }) => {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const rootModalElement = document.getElementById('root-modal');
    setPortalElement(rootModalElement);

    return () => {
      setPortalElement(null);
    };
  }, []);

  return portalElement ? createPortal(children, portalElement) : null;
};
