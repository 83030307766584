import React from 'react';
import { AdminFormLayout } from '../../layouts/FormLayout';
import { CreateTaskForm } from './CreateTaskForm';

export const CreateTaskPage: React.FC = () => {
  return (
    <AdminFormLayout>
      <CreateTaskForm />
    </AdminFormLayout>
  );
};
