import { Column } from 'react-table';
import { TasksFoldersTableRow } from '../../../lib/types/tables';

export const columnList: Column<TasksFoldersTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'TITLE',
    accessor: 'title',
  },
  {
    Header: 'STATUS',
    accessor: 'statusId',
    maxWidth: 150,
  },
  {
    Header: 'SPENT',
    accessor: 'spentTime',
    maxWidth: 120,
  },
];

export const TableData = [
  {
    title: '1-to-1 - EG ###Internal Management###',
    statusId: '1',
    spentTime: '16:25',
    id: '23zds4xadxxczxc',
  },
  {
    title: 'Example folder name',
    statusId: '1',
    spentTime: '16:25',
    id: '23zdsdasd34xad',
  },
  {
    title: 'Example folder name',
    statusId: '1',
    spentTime: '16:25',
    id: '23zdsdasd3dsfx4xad',
  },
  {
    title: 'Example folder name',
    statusId: '1',
    spentTime: '16:25',
    id: '23zdsdasd3454xad',
  },
  {
    title: 'Example folder name',
    statusId: '1',
    spentTime: '16:25',
    id: '23zdsdasd3cdc4xad',
  },
  {
    title: 'Example folder name',
    statusId: '1',
    spentTime: '16:25',
    id: '23zdsdasd34sdsaxad',
  },
];
