import React from 'react';
import { useForm } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useStore } from '../../../../../lib/store';
import { REPORT_TEMPLATE } from '../../../../../lib/types/store';
import { ClientEmailReportForm } from '../../GeneralReportPage/GeneralReportForm';
import { ChooseProject } from '../../reportForm/ChooseProject';
import { ChooseTasks } from '../../reportForm/ChooseTasks';
import { ChooseTeam } from '../../reportForm/ChooseTeam';
import { CustomerInfo } from '../../reportForm/CustomerInfo';
import { ReportFormStepBar } from '../../reportForm/ReportFormStepBar';

export const ReportFormByPeople: React.FC = () => {
  const byPeopleFormStep = useStore((state) => state.byPeopleFormStep);
  const updateFormStep = useStore((state) => state.updateFormStep);
  const resetAllFormsStep = useStore((state) => state.resetAllFormsStep);
  const {
    reportTemplate,
    reportingPeriod,
    monthlyReportingPeriod,
    clientIdList,
    projectIdList,
    teamMemberList,
    commentsType,
  } = useStore(
    (state) => ({
      reportTemplate: state.reportTemplate,
      reportingPeriod: state.reportingPeriod,
      monthlyReportingPeriod: state.monthlyReportingPeriod,
      clientIdList: state.clientIdList,
      projectIdList: state.projectIdList,
      teamMemberList: state.teamMemberList,
      commentsType: state.commentsType,
    }),
    shallow
  );

  const { register, handleSubmit } = useForm<ClientEmailReportForm>({
    mode: 'onChange',
  });
  const onSubmit = (formData: any) => {
    resetAllFormsStep();
    const compiledData = {
      ...formData,
      reportTemplate,
      reportingPeriod,
      monthlyReportingPeriod,
      clientIdList,
      projectIdList,
      teamMemberList,
      commentsType,
    };
  };

  return (
    <div className="h-full flex flex-col px-12 pl-24 pr-8">
      <div className="mb-8">
        <ReportFormStepBar
          steps={['Report', 'Period', 'Users', 'Tasks', 'Client']}
          activeStep={byPeopleFormStep}
        />
        <p className="font-md text-2xl text-gray-900 pb-6">
          {byPeopleFormStep === 1 &&
            "Let's choose which project you want and what period you need a report."}
          {byPeopleFormStep === 2 &&
            'Select the team members for which you want to make a report.'}
          {byPeopleFormStep === 3 &&
            'Now we need to select the tasks for which there will be a report. '}
          {byPeopleFormStep === 4 &&
            'The last step! You have to enter the customer info, if it is not loaded. '}
        </p>
        <div className="flex flex-col space-y-6">
          {byPeopleFormStep === 1 && (
            <ChooseProject template={reportTemplate} />
          )}
          {byPeopleFormStep === 2 && <ChooseTasks />}
          {byPeopleFormStep === 3 && <ChooseTeam />}
          {byPeopleFormStep === 4 && <CustomerInfo register={register} />}
        </div>
      </div>
      <div className="w-full">
        {byPeopleFormStep < 4 && (
          <button
            onClick={() =>
              updateFormStep(byPeopleFormStep + 1, REPORT_TEMPLATE.byPeople)
            }
            className="bg-blue-500 text-white py-2.5 px-10 rounded"
          >
            Continue
          </button>
        )}
        {byPeopleFormStep === 4 && (
          <>
            <button
              onClick={handleSubmit(onSubmit)}
              className="bg-blue-500 text-white py-2.5 px-10 rounded no-wrap text-center"
            >
              Send to clients
            </button>
            <button className=" text-gray-500 py-2.5 px-10 rounded text-center">
              Download
            </button>
          </>
        )}
      </div>
    </div>
  );
};
