import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { DashboardPage } from './components/pages/DashboardPage';
import { UsersPage } from './components/pages/UsersPage';
import { ProjectsPage } from './components/pages/ProjectsPage';
import { TasksPage } from './components/pages/TasksPage';
import { SettingsPage } from './components/pages/SettingsPage';
import { CreateUserPage } from './components/pages/CreateUserPage';
import { CreateTaskPage } from './components/pages/CreateTaskPage';
import { GeneralReportPage } from './components/pages/createReportPages/GeneralReportPage';
import { ReportByPeoplePage } from './components/pages/createReportPages/ReportByPeoplePage';
import { ReportByLabelsPage } from './components/pages/createReportPages/ReportByLabelsPage';
import { ReportByTasksPage } from './components/pages/createReportPages/ReportByTasksPage';
import { AdminCreateTeamPage } from './components/pages/CreateTeamPage';
import { CreateReportPage } from './components/pages/CreateReportPage';
import { CreateProjectPage } from './components/pages/CreateProjectPage';

const App: React.FC = () => {

  return (
    <div className="min-h-screen bg-gray-200 font-[Helvetica Neue]">
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          // TODO: uncomment when keycloack realization will be finished
          onLoad: 'login-required',
          checkLoginIframe: false,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/tasks" element={<TasksPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/createUser" element={<CreateUserPage />} />
            <Route path="/createTask" element={<CreateTaskPage />} />
            <Route path="/createTeam" element={<AdminCreateTeamPage />} />
            <Route path="/createReport" element={<CreateReportPage />} />
            <Route path="/createProject" element={<CreateProjectPage />} />
            <Route
              path="/createReport/general"
              element={<GeneralReportPage />}
            />
            <Route
              path="/createReport/byPeople"
              element={<ReportByPeoplePage />}
            />
            <Route
              path="/createReport/byLabels"
              element={<ReportByLabelsPage />}
            />
            <Route
              path="/createReport/byTasks"
              element={<ReportByTasksPage />}
            />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
};

export default App;
