import classNames from 'classnames';
import { ChangeEvent, useState } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { useStore } from '../../../../lib/store';
import { EntityItem } from '../../../../lib/types/store';
import { ProjectShape } from '../../ProjectTableDetailModal/ProjectTableDetailModal';

type ProjectInfoTabProps = {
  project: ProjectShape;
  errors: FieldErrorsImpl<ProjectShape>;
  register: UseFormRegister<ProjectShape>;
};

export const ProjectInfoTab: React.FC<ProjectInfoTabProps> = ({
  project,
  register,
  errors,
}) => {
  const clientTypeList = useStore((state) => state.clientTypeList);
  const [isCommercial, setIsCommercial] = useState(false);
  //TODO: refactor for getting project by id from projectList in State
  return (
    <div className="w-full h-full">
      <form className="flex flex-col h-full">
        <div className="px-4 flex-1">
          <div className="w-full my-8 flex border-b border-gray-300">
            <div className="w-1/2  pr-6">
              <p className="text-gray-600 font-medium">Links</p>
              <p className="text-gray-500 max-w-[250px] text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus
                amet lectus ultricies cras scelerisque dui fermentum.
              </p>
            </div>
            <div className="w-full">
              <label className="text-sm text-gray-400" htmlFor="email">
                Jira
              </label>
              <input
                type="jira"
                {...register('jira')}
                defaultValue="Link"
                value={project.jira}
                className="w-full rounded p-2 mb-6 selected:bg-white border-gray-200 border"
              />
              <p className="text-red-500 italic text-xs pb-1 mb-2 hidden">
                {errors.jira?.message && 'Not valid jira link'}
              </p>
              <div className="w-full relative">
                <label className="text-sm text-gray-400" htmlFor="password">
                  Trello
                </label>
                <input
                  value={project.trello}
                  defaultValue="Link"
                  {...register('trello')}
                  className="w-full rounded p-2 mb-6 border-gray-200 border"
                />
                <p className="text-red-500 italic text-xs pb-1 hidden">
                  {errors.trello?.message && 'Not valid trello link'}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/2 pr-6">
              <div className="text-gray-600 font-medium flex flex-wrap pb-2">
                <p className="whitespace-nowrap pr-2">Commercial project</p>
                <label
                  htmlFor="isCommercial"
                  className="inline-flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    name="notifications"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const checkbox = event.target;
                      setIsCommercial(checkbox.checked);
                    }}
                    checked={isCommercial}
                    id="isCommercial"
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
                </label>
              </div>
              <p className="text-gray-500 max-w-[250px] text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus
                amet lectus ultricies cras scelerisque dui fermentum.
              </p>
            </div>
            <div
              className={classNames('w-full', {
                hidden: !isCommercial,
              })}
            >
              <label htmlFor="client-email" className="text-sm text-gray-400 ">
                Client email
              </label>
              <input
                value={project.client?.email}
                {...register('client.email')}
                className="w-full rounded p-2 border-gray-200 border-2 bg-white  mb-6"
              />
              <label htmlFor="status" className="text-sm text-gray-400">
                Status
              </label>
              <select
                defaultValue={project.client?.type}
                {...register('client.type')}
                className="w-full rounded p-2 border-gray-200 border-2 bg-white"
              >
                {clientTypeList.map(({ id, name }: EntityItem) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 border-t h-20 flex justify-end items-center px-4">
          <button type="button" className="bg-blue-500 text-white p-2 rounded">
            Create report
          </button>
        </div>
      </form>
    </div>
  );
};
