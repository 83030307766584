import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import React, { useEffect } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { columnList, TableData } from './Data';
import { HistoryTableRow } from '../../../lib/types/tables';
import classNames from 'classnames';
import { showToast } from '../../../lib/toast/showToast';

type HistoryTableProps = {
  onDelete?: () => void;
  onEdit?: () => void;
  hiddenColumns: (keyof HistoryTableRow)[];
};

export const HistoryTable: React.FC<HistoryTableProps> = ({
  hiddenColumns = [],
}) => {
  const historyTableData = useStore((state) => state.historyTableData);
  const updateHistoryTableData = useStore(
    (state) => state.updateHistoryTableData
  );
  const data = React.useMemo<HistoryTableRow[]>(
    () => historyTableData,
    [historyTableData]
  );
  const columns = React.useMemo<Column<HistoryTableRow>[]>(
    () => columnList,
    []
  );
  const initialState = { hiddenColumns };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  const getFilteredData = async (filter?: string) => {
    await fetch('http://localhost:3000')
      .then(() => {
        updateHistoryTableData(TableData);
      })
      .catch((error) =>
        showToast({
          type: 'error',
          title: 'Fetch error',
          message: 'response errror catched',
        })
      );
  };

  useEffect(() => {
    getFilteredData();
  }, []);

  return (
    <table
      {...getTableProps()}
      className="w-full text-xs border border-gray-200"
    >
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div className="flex justify-start items-center relative min-w-[100px] max-w-[350px]">
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="h-10 text-left border-b">
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4 mr-auto">
                    <div className="flex items-center justify-between">
                      <div
                        className={classNames({
                          'rounded-xl text-orange-500 bg-orange-100 px-2 py-1':
                            cell.column.id === 'status',
                        })}
                      >
                        {cell.render('Cell')}
                      </div>

                      {cell.column.id === 'status' && (
                        <div className="flex">
                          <HiOutlinePencilAlt className="w-6 h-6 bg-gray-200 text-gray-700 rounded-3xl p-1 mx-1" />
                          <HiOutlineTrash className="w-6 h-6 bg-gray-200 text-gray-700 rounded-3xl p-1 mx-1" />
                        </div>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
