import React from 'react';
import { HiX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

export const AdminFormLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-full min-h-screen bg-gray-50">
      <div className="flex justify-end w-full h-10 px-8 my-8">
        <div className="h-10 w-10 rounded-full bg-white flex justify-center items-center shadow">
          <HiX
            className="w-6 h-6"
            onClick={() => navigate('/dashboard')}
          />
        </div>
      </div>
      <div className="h-full">{children}</div>
    </div>
  );
};
