import { TasksTable } from '../../tables/TasksTable';
import { Portal } from '../Modal/Portal';

export type CalendarTaskModalProps = {
  readonly isModalVisible: boolean;
  readonly onClose: () => void;
  readonly title: string;
};

export const CalendarTaskModal: React.FC<CalendarTaskModalProps> = ({
  isModalVisible,
  onClose,
  title,
}) => {
  // TODO : add expander for task table item
  return isModalVisible ? (
    <Portal>
      <div className="absolute inset-0 z-20 bg-[rgba(0,0,0,60%)] shadow-md flex flex-col justify-center items-center p-6">
        <div className="max-w-[1100px] h-[500px] bg-white p-10 rounded">
          <p className="text-xl font-medium pb-6">{title}</p>
          <div className="h-[300px] overflow-y-auto mb-12">
            <TasksTable isCheckboxVisible={false} onItemClick={() => {}} hiddenColumns={['id','projectId','pl']} />
          </div>
          <div className="w-full flex justify-end text-base font-medium">
            <button
              type="button"
              className="bg-blue-500 text-white px-10 py-2 rounded"
              onClick={() => onClose()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Portal>
  ) : null;
};
