import { Column } from 'react-table';
import { ProjectsTableRow } from '../../../lib/types/tables';

export const validDate = new Date('01/01/2020')
export const columnList: Column<ProjectsTableRow>[] = [
  {
    Header: 'PROJECT NAME',
    accessor: 'name',
  },
  {
    Header: 'TEAM',
    accessor: 'teamId',
  },
  {
    Header: 'PM',
    accessor: 'pm',
  },
  {
    Header: 'CLIENT',
    accessor: 'client',
  },
  {
    Header: 'NEXT REPORT',
    accessor: 'report',
  },
];

export const TableData = [
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/10/2000',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
  {
    name: 'Name value',
    teamId: '1',
    pm: 'Julia Roberts',
    client: 'Adam Adams',
    report: '12/12/2020',
  },
];
