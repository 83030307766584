import { toast, ToastOptions, TypeOptions } from 'react-toastify';
import { ToastPopupUI } from '../../components/common/ToastPopupUI';
import { getIconByType } from './getIconByType';

type ShowToast = ({
  type,
  title,
  message,
}: {
  type: TypeOptions;
  title: string;
  message?: string;
}) => void;

export const contextClass = {
  success: 'bg-green-100',
  error: 'bg-red-100',
  info: 'bg-indigo-100',
  warning: 'bg-orange-100',
  default: 'bg-indigo-100',
};

export const showToast: ShowToast = ({ type, title, message }) => {
  const toastOptions: ToastOptions = {
    icon: getIconByType(type),
  };

  switch (type) {
    case 'warning':
      return toast.warning(ToastPopupUI({ title, message }), toastOptions);
    case 'success':
      return toast.success(ToastPopupUI({ title, message }), toastOptions);
    case 'error':
      return toast.error(ToastPopupUI({ title, message }), toastOptions);
    default:
      return toast.info(ToastPopupUI({ title, message }), toastOptions);
  }
};
