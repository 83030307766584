import React from 'react';
import { ReportFormByLabels } from './ReportFormByLabels';
import { ReportFormLayout } from '../../../layouts/ReportFormLayout';

export const ReportByLabelsPage: React.FC = () => {
  return (
    <ReportFormLayout>
      <ReportFormByLabels />
    </ReportFormLayout>
  );
};
