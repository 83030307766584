import { useFieldArray, useForm } from 'react-hook-form';
import { SingleDateInput } from '../../../common/SingleDateInput';
import { HiCheck, HiChevronDown } from 'react-icons/hi';
import { ReportFormTableView } from '../../createReportPages/reportForm/ReportFormTableView';

type ReportForm = {
  projects: { id: string; startDate: string; projectName: string }[];
};

export const CreateReportForm = () => {
  const {
    setValue,
    control,
    register,
    formState: { isDirty },
    handleSubmit,
  } = useForm<ReportForm>({
    defaultValues: {
      projects: [
        { projectName: 'Project #1', startDate: '01.01.2023' },
        { projectName: 'Project #2', startDate: '01.01.2023' },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'projects',
  });

  const onSubmit = () => {
    // TODO: Connect Endpoint
  };

  return (
    <div className="h-full flex flex-col px-12 bg-gray-50 pb-6">
      <form
        className="pl-24 pr-8 py-4 bg-white shadow mb-14"
        onSubmit={() => handleSubmit(onSubmit)}
      >
        <p className="pt-10 pb-6 text-2xl text-gray-900 font-medium">
          Report Creator
        </p>
        <div className="px-1 py-8 border-y flex justify-between">
          <div className="w-1/5">
            <p className="text-gray-600 text-base pb-1">Info</p>
            <p className="text-gray-500 text-sm pb-2">
              Let's choose which project you want and what period you need a
              report.
            </p>
            <button
              type="button"
              className="text-blue-500 text-base"
              onClick={() => {
                append({
                  id: `${fields.length + 1}`,
                  projectName: `Project${fields.length + 1}`,
                  startDate: '01.01.2023',
                });
              }}
            >
              Add one more project
            </button>
          </div>

          <div className="flex w-4/5 px-4">
            <div className="flex">
              {fields.map((field, index) => (
                <div key={field.id} className="px-2">
                  <label
                    htmlFor="projectName"
                    className="text-sm text-gray-400"
                  >
                    Project #1
                  </label>
                  <input
                    defaultValue="Project #1"
                    {...register(`projects.${index}.projectName` as const)}
                    className="w-full rounded p-2 mt-1.5 border-gray-200 border-2 bg-white text-gray-700 font-normal"
                  />

                  <label htmlFor="startDate1" className="text-sm text-gray-400">
                    Start Date
                  </label>
                  <SingleDateInput
                    onDateChange={(date: string) => {
                      setValue(`projects.${index}.startDate`, date);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="px-1 py-8 flex">
            <div className="w-1/5 px-4">
              <p className="text-gray-600 text-base pb-1">Tasks</p>
              <p className="text-gray-500 text-sm pb-2">
                Let's choose which project you want and what period you need a
                report.
              </p>
            </div>
            <div className="flex px-6 flex-col">
              <div className=" h-10 w-72 border flex justify-between px-2 items-center text-gray-600">
                <div className="flex justify-between items-center">
                  <div className="w-4 h-4 border flex justify-center items-center mr-2">
                    <HiChevronDown />
                  </div>
                  <p>Name</p>
                </div>
                <p>Spent Time</p>
              </div>
              <div className="h-10 w-72 border flex justify-between px-2 items-center text-gray-900">
                <div className="flex justify-between items-center">
                  <div className="w-4 h-4 border flex justify-center items-center bg-blue-500 mr-2">
                    <HiCheck className="text-white" />
                  </div>
                  <p>All your tasks</p>
                </div>
                <p>102</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-10 py-2 rounded right-0 disabled:bg-blue-300"
            disabled={!isDirty}
          >
            Enter parameters
          </button>
        </div>
      </form>

      <div className="bg-white h-full w-full px-12 mb-8">
        <p className="text-2xl text-gray-900 pt-10 pb-4 font-medium">Example</p>
        <ReportFormTableView />
      </div>
    </div>
  );
};
