import { useEffect } from 'react';
import { useStore } from '../../../../../lib/store';
import { showToast } from '../../../../../lib/toast';
import { SearchBar } from '../../../../common/SearchBar';
import { TasksTable } from '../../../../tables/TasksTable';
import { TableData } from '../../../../tables/TasksTable/Data';

export const ChooseTasks = () => {
  const updateTasksTableData = useStore((state) => state.updateTasksTableData);
  const tasksTableData = useStore((state) => state.tasksTableData);
  const getTasksTableData = async () => {
    // TODO : Replace with not-fake API call when backend create endpoints
    if (!tasksTableData.length) {
      await fetch('http://localhost:3000')
        .then(() => {
          updateTasksTableData(
            TableData.map((task) => {
              return {
                ...task,
                enteredTime: '100',
                plannedTime: '100',
                status: 'In process',
                startDate: '01.01.2000',
                jira: 'Jira link',
              };
            })
          );
        })
        .catch((error) =>
          showToast({
            type: 'error',
            title: 'Fetch error',
            message: 'response errror catched',
          })
        );
    }
  };

  useEffect(() => {
    getTasksTableData();
  }, []);

  return (
    <>
      <div className="bg-white">
        <SearchBar className="p-2.5 pl-10 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" />
      </div>
      <div className="bg-white border border-gray-100 rounded-lg overflow-y-auto max-h-[550px]">
        <TasksTable
          onItemClick={() => {}}
          hiddenColumns={['id', 'projectId', 'pl']}
        />
      </div>
    </>
  );
};
