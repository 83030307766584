import classNames from 'classnames';
import React from 'react';
import { isAfter } from 'date-fns';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from './../../../lib/store';
import { columnList, validDate } from './Data';
import { ProjectsTableRow } from '../../../lib/types/tables';
import { showToast } from '../../../lib/toast';

type ProjectsTableProps = {
  onItemClick: () => void;
};
export const ProjectsTable: React.FC<ProjectsTableProps> = ({
  onItemClick,
}) => {
  const projectsTableData = useStore((state) => state.projectsTableData);
  const getTeamById = useStore((state) => state.getTeamById);
  const updateProjectsTableData = useStore(
    (state) => state.updateProjectsTableData
  );

  const data = React.useMemo<ProjectsTableRow[]>(
    () =>
      projectsTableData.map(({ name, report, teamId, client, pm }) => {
        return {
          name: name || '-',
          report: report || '-',
          teamId: getTeamById(teamId) || '-',
          client: client || '-',
          pm: pm || '-',
        };
      }),
    [projectsTableData]
  );
  const columns = React.useMemo<Column<ProjectsTableRow>[]>(
    () => columnList,
    []
  );

  const isDateValid = (comparedDate: Date) => isAfter(validDate, comparedDate);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const onFiltersClick = async (data: any) => {
    getFilteredData(data.id);
  };

  const getFilteredData = async (filter: string) => {
    await fetch('http://localhost:3000')
      .then(() => {
        updateProjectsTableData(projectsTableData);
      })
      .catch((error) =>
        showToast({
          type: 'error',
          title: 'Fetch error',
          message: 'response errror catched',
        })
      );
  };

  return (
    <table {...getTableProps()} className="w-full text-xs">
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div
                  className="flex justify-start items-center relative "
                  onClick={(event) => onFiltersClick(column)}
                >
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="h-10 text-left border-b">
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="first:text-blue-500 px-4 mr-auto"
                  >
                    <div
                      className={classNames({
                        'text-red-500':
                          cell.column.id === 'report' &&
                          isDateValid(new Date(cell.value)),
                      })}
                    >
                      <div
                        className="flex items-center"
                        {...{
                          onClick:
                            cell.column.id === 'name' ? onItemClick : undefined,
                        }}
                      >
                        {cell.value && cell.column.id === 'teamId' && (
                          <div className="w-2 h-2 rounded-xl mr-2 bg-red-400" />
                        )}
                        {cell.render('Cell')}
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
