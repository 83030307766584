import { useState } from 'react';
import { Calendar, Event, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import addHours from 'date-fns/addHours';
import startOfHour from 'date-fns/startOfHour';
import './calendar.css';
import { CalendarToolbar } from '../CalendarToolbar';

const locales = {
  'en-US': enUS,
};
const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1);
const now = new Date();
const start = endOfHour(now);
const end = addHours(start, 2);

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type CalendarComponentProps = {
  onEventClick?: () => void;
};

export const CalendarComponent: React.FC<CalendarComponentProps> = ({
  onEventClick,
}) => {
  const [events, setEvents] = useState<Event[]>([
    {
      start,
      end,
      allDay: true,
      title: '8 hrs',
    },
  ]);

  return (
    <div className="h-full bg-white">
      <Calendar
        defaultView="month"
        events={events}
        localizer={localizer}
        components={{
          toolbar: CalendarToolbar,
        }}
        onSelectEvent={() => onEventClick?.()}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};
