import React, { useState } from 'react';
import { MainLayout } from '../../layouts/MainLayout';
import { CalendarTaskModal } from '../../modals/CalendarTaskModal';
import { CalendarComponent } from './Calendar';
import { DashboardStatistics } from './DashboardStatistics';
import { Records } from './Records';

export const DashboardPage: React.FC = () => {
  const [isTaskInfoVisible, setIsTaskInfoVisible] = useState(false);

  return (
    <MainLayout title="Admin Page">
      <div className="flex">
        <div className="w-4/6 bg-gray-100 flex flex-col p-5">
          <DashboardStatistics />
          <div id="calendar" className="flex-1 max-h-[600px] w-full pb-10">
            <CalendarComponent
              onEventClick={() => setIsTaskInfoVisible(true)}
            />
            <CalendarTaskModal
              title="Info list"
              isModalVisible={isTaskInfoVisible}
              onClose={() => setIsTaskInfoVisible(false)}
            />
          </div>
        </div>
        <div id="notificaions" className="w-2/6 bg-gray-100">
          <p className="text-xl font-medium pt-10 pb-5">
            Records for the month
          </p>
          <Records />
        </div>
      </div>
    </MainLayout>
  );
};
