import { Column } from 'react-table';
import { ReportsTableRow } from '../../../lib/types/tables';

export const columnList: Column<ReportsTableRow>[] = [
  {
    Header: 'PERIOD',
    accessor: 'period', // accessor is the "key" in the data
  },
  {
    Header: 'CREATION DATE',
    accessor: 'createdDate',
  },
  {
    Header: 'TEMPLATE',
    accessor: 'template',
  },
  {
    Header: 'AUTHOR',
    accessor: 'author',
  },
];

export const TableData = [
  {
    period: '12.12.2022-12.01.2023',
    createdDate: '12.12.2022',
    template: 'Template 1',
    author: 'Alex Andreev',
  },
  {
    period: '12.12.2022-12.01.2023',
    createdDate: '12.12.2022',
    template: 'Template 1',
    author: 'Alex Andreev',
  },
  {
    period: '12.12.2022-12.01.2023',
    createdDate: '12.12.2022',
    template: 'Template 1',
    author: 'Alex Andreev',
  },
  {
    period: '12.12.2022-12.01.2023',
    createdDate: '12.12.2022',
    template: 'Template 1',
    author: 'Alex Andreev',
  },
];
