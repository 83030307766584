import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import { ProjectInfoTab } from '../ModalTabsTemplates/ProjectInfoTab';
import { ProjectTeamTab } from '../ModalTabsTemplates/ProjectTeamTab';
import { ReportTab } from '../ModalTabsTemplates/ReportTab';

export type ProjectShape = {
  name: string;
  jira?: string;
  trello?: string;
  isCommercial?: boolean;
  client?: {
    email: string;
    type: string;
  };
};

export type ProjectInfoModalProps = {
  readonly project: ProjectShape;
  readonly title?: string;
  readonly closeModal: () => void;
};

enum ProjectTabs {
  info = 'INFO',
  reports = 'REPORTS',
  team = 'TEAM',
}

export const ProjectInfoModal: React.FC<ProjectInfoModalProps> = ({
  project,
  closeModal,
}) => {
  const [currentTab, setCurrentTab] = useState(ProjectTabs.info);
  const {
    register,
    formState: { errors },
  } = useForm<ProjectShape>({
    mode: 'onChange',
  });

  return (
    <div className="flex flex-col h-full">
      <div>
        <div className="flex justify-between items-start p-4 rounded-t">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {project?.name}
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={() => closeModal()}
          >
            <HiX className="w-5 h-5" />
          </button>
        </div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
          <ul className="flex flex-wrap -mb-p text-sm">
            <li className="mr-2">
              <button
                onClick={() => setCurrentTab(ProjectTabs.info)}
                className={classNames(
                  'inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                  { 'text-black border-black': currentTab === ProjectTabs.info }
                )}
              >
                Info
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setCurrentTab(ProjectTabs.reports)}
                className={classNames(
                  'inline-block py-4 px-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                  {
                    'text-black border-black':
                      currentTab === ProjectTabs.reports,
                  }
                )}
              >
                Reports
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setCurrentTab(ProjectTabs.team)}
                className={classNames(
                  'inline-block py-4 px-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300',
                  { 'text-black border-black': currentTab === ProjectTabs.team }
                )}
              >
                Team
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-1">
        {currentTab === ProjectTabs.info && (
          <ProjectInfoTab
            register={register}
            project={project}
            errors={errors}
          />
        )}
        {currentTab === ProjectTabs.reports && <ReportTab />}
        {currentTab === ProjectTabs.team && <ProjectTeamTab />}
      </div>
    </div>
  );
};
