import {
  notificationVariants,
  NotificatonOptions,
} from '../../../../lib/utils/variables';

type NotificationTabProps = {
  onToggleChange: (variant: string, value: NotificatonOptions) => void;
};

export const NotificationTab: React.FC<NotificationTabProps> = ({
  onToggleChange,
}) => (
  <div className="flex w-full flex-col">
    {notificationVariants.map((variant) => (
      <section className="p-4 mb-2" key={variant}>
        <div className="flex mb-4">
          <div className="max-w-[260px] text-base font-medium text-gray-600">
            <h3 className="mb-1">{variant}</h3>
            <p className="text-sm text-gray-500">
              These will be notifications that will let you know when a report
              deadline is approaching.
            </p>
          </div>
          <fieldset className="flex flex-col justify-start space-y-2 text-gray-600">
            <label
              htmlFor={`${variant}_push`}
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="radio"
                name="notifications"
                onChange={() =>
                  onToggleChange(variant, NotificatonOptions.push)
                }
                value="PUSH"
                id={`${variant}_push`}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
              <span className="ml-3 text-sm font-medium">Push</span>
            </label>
            <label
              htmlFor={`${variant}_email`}
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="radio"
                value="EMAIL"
                name="notifications"
                onChange={() =>
                  onToggleChange(variant, NotificatonOptions.email)
                }
                id={`${variant}_email`}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
              <span className="ml-3 text-sm font-medium">Email</span>
            </label>
            <label
              htmlFor={`${variant}_telegram`}
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="radio"
                value="TELEGRAM"
                name="notifications"
                onChange={() =>
                  onToggleChange(variant, NotificatonOptions.telegram)
                }
                id={`${variant}_telegram`}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
              <span className="ml-3 text-sm font-medium">Telegram</span>
            </label>
          </fieldset>
        </div>
      </section>
    ))}
  </div>
);
