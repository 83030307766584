import { HiOutlineFolderOpen } from 'react-icons/hi';
import React from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { columnList, TableData } from './Data';
import { TeamsFoldersTableRow } from '../../../lib/types/tables';
import { showToast } from '../../../lib/toast';

type TeamsFoldersTableProps = {
  onCheckBoxClick?: (value: string, checked: boolean) => void;
};

export const TeamsFoldersTable: React.FC<TeamsFoldersTableProps> = ({
  onCheckBoxClick,
}) => {
  const teamsFoldersTableData = useStore(
    (state) => state.teamsFoldersTableData
  );
  const updateTeamsFoldersTableData = useStore(
    (state) => state.updateTeamsFoldersTableData
  );

  const data = React.useMemo<TeamsFoldersTableRow[]>(
    () =>
      teamsFoldersTableData.map(({ id, teamName, membersCount }) => {
        return {
          id,
          teamName: teamName || '-',
          membersCount: membersCount || 0,
        };
      }),
    [teamsFoldersTableData]
  );
  const columns = React.useMemo<Column<TeamsFoldersTableRow>[]>(
    () => columnList,
    []
  );
  const initialState = { hiddenColumns: ['id'] };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  const onFiltersClick = async (data: any) => {
    getFilteredData(data.id);
  };
  const getFilteredData = async (filter?: string) => {
    await fetch('http://localhost:3000')
      .then(() => {
        updateTeamsFoldersTableData(TableData);
      })
      .catch((error) =>
        showToast({
          type: 'error',
          title: 'Fetch error',
          message: 'response errror catched',
        })
      );
  };

  return (
    <table
      {...getTableProps()}
      className="w-full text-xs border-y border-gray-200"
    >
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div
                  className="flex justify-start items-center relative "
                  onClick={(event) => onFiltersClick(column)}
                >
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="h-10 text-left border-b">
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4 mr-auto">
                    <div className="flex items-center justify-between">
                      {onCheckBoxClick && cell.column.id === 'title' && (
                        <>
                          <input
                            onChange={(e) =>
                              onCheckBoxClick(row.values.id, e.target.checked)
                            }
                            type="checkbox"
                            className="w-3 h-3 rounded-xl mr-2"
                          />
                          <HiOutlineFolderOpen className="mr-2" />
                        </>
                      )}
                      {cell.render('Cell')}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
