import React from 'react';
import { CreateUserForm } from './CreateUserForm';
import { AdminFormLayout } from '../../layouts/FormLayout';

export const CreateUserPage: React.FC = () => {
  return (
    <AdminFormLayout>
      <CreateUserForm />
    </AdminFormLayout>
  );
};
