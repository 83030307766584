import classNames from 'classnames';
import React from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import shallow from 'zustand/shallow';
import { columnList } from './Data';
import { UsersTableRow } from '../../../lib/types/tables';
import { showToast } from '../../../lib/toast';

export type UserTableProps = {
  onItemClick: (value: string) => void;
};

export const UsersTable: React.FC<UserTableProps> = ({ onItemClick }) => {
  const {
    getRoleById,
    getStatusById,
    getTeamById,
    getPositionById,
    usersTableData,
    updateUsersTableData,
  } = useStore(
    (state) => ({
      getRoleById: state.getRoleById,
      getStatusById: state.getStatusById,
      getTeamById: state.getTeamById,
      getPositionById: state.getPositionById,
      usersTableData: state.usersTableData,
      updateUsersTableData: state.updateUsersTableData,
    }),
    shallow
  );

  const data = React.useMemo<UsersTableRow[]>(
    () =>
      usersTableData.map(
        ({ id, name, statusId, roleId, teamId, positionId }) => {
          return {
            id,
            name: name || '-',
            roleId: getRoleById(roleId!) || '-',
            statusId: getStatusById(statusId!) || '-',
            teamId: getTeamById(teamId!) || '-',
            positionId: getPositionById(positionId!) || '-',
          };
        }
      ),
    [usersTableData, getRoleById, getStatusById, getTeamById, getPositionById]
  );
  const columns = React.useMemo<Column<UsersTableRow>[]>(() => columnList, []);
  const initialState = { hiddenColumns: ['teamId'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  const onFiltersClick = async (data: any) => {
    getFilteredData(data.id);
  };
  const getFilteredData = async (filter: string) => {
    await fetch('http://localhost:3000')
      .then(() => {
        updateUsersTableData(usersTableData);
      })
      .catch((error) =>
        showToast({
          type: 'error',
          title: 'Fetch error',
          message: 'response errror catched',
        })
      );
  };

  return (
    <table {...getTableProps()} className="w-full text-xs">
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div
                  className="flex justify-start items-center relative "
                  onClick={() => onFiltersClick(column)}
                >
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="h-10 text-left border-b"
              onClick={() => {
                onItemClick(row.values.id);
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4 mr-auto">
                    <div
                      className={classNames({
                        'rounded-xl py-1 px-1.5 text-center max-w-[60px]':
                          cell.column.id === 'statusId',
                        'bg-green-100 text-green-500':
                          cell.column.id === 'statusId' &&
                          cell.value === 'Working',
                        'bg-red-100 text-red-500':
                          cell.column.id === 'statusId' &&
                          cell.value === 'Paused',
                        'bg-yellow-100 text-yellow-500':
                          cell.column.id === 'statusId' &&
                          cell.value === 'Vacation',
                      })}
                    >
                      <div
                        className={classNames('flex items-center w-full', {
                          'justify-center': cell.column.id === 'statusId',
                        })}
                      >
                        {cell.value &&
                          cell.value !== '-' &&
                          cell.column.id === 'teamId' && (
                            <div className="w-2 h-2 rounded-xl mr-2 bg-red-400" />
                          )}
                        {cell.render('Cell')}
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
