import { useState } from 'react';
import { ReportsTable } from '../../../tables/ReportsTable';
import { AutoReportForm } from '../../ProjectTableDetailModal/AutoReportForm';

export const ReportTab: React.FC = () => {
  const [isAutomated, setIsAutomated] = useState<boolean>(false);

  return (
    <form className="flex flex-col w-full h-full">
      <div className="w-full flex flex-col h-full">
        <div className="mx-6 py-8 flex">
          <div className="text-gray-600 font-medium">
            <p className="whitespace-nowrap pr-2">Automatic sending</p>
            <label
              htmlFor="isAutomated"
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                name="notifications"
                id="isAutomated"
                checked={isAutomated}
                className="sr-only peer"
                onChange={() => {
                  setIsAutomated(!isAutomated);
                }}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
            </label>
            <p className="text-gray-500 max-w-[250px] text-sm">
              It will be carried out in the rules you set for it. Be sure to
              enter the client's email address carefully.
            </p>
          </div>
          {isAutomated && <AutoReportForm />}
        </div>
        {!isAutomated && (
          <>
            <div className="text-gray-600 px-5 pt-8 pb-3  border-t border-gray-300">
              Reports
            </div>
            <div className="flex-1 px-5">
              <ReportsTable onDelete={() => {}} onDownload={() => {}} />
            </div>
          </>
        )}
      </div>
      <div className="bg-gray-100 border-t h-20 flex justify-end items-center px-4">
        <button type="button" className="bg-blue-500 text-white p-2 rounded">
          Create report
        </button>
      </div>
    </form>
  );
};
