import React from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../../lib/store';
import { UserInfoTab } from '../../../modals/ModalTabsTemplates/UserInfoTab';
import { UserProfileShape } from '../../../modals/UserInfoModal';

export const CreateUserForm: React.FC = () => {
  const updateUsersTableData = useStore((state) => state.updateUsersTableData);
  const usersTableData = useStore((state) => state.usersTableData);

  const { register, formState, handleSubmit } =
    useForm<UserProfileShape>({
      defaultValues: {
        password: '123456',
        email: 'example@email.com',
      },
      mode: 'onChange',
    });

  const onSubmit = (data: UserProfileShape) => {
    updateUsersTableData([
      ...usersTableData,
      {
        ...data,
        id: String(Math.round(Math.random() * 100)),
        name: 'Dummy user',
      },
    ]);
  };

  return (
    <div className="h-full max-w-[400px] mx-auto shadow bg-white px-12 py-10">
      <p className="font-md text-xl text-gray-800 pb-6">Add User</p>
      <UserInfoTab register={register} formState={formState} />
      <div className="w-full">
        <button
          className="bg-blue-500 text-white p-2 rounded w-full"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </button>
        <button
          type="button"
          className="bg-white text-blue-500 p-2 rounded w-full"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
