import classNames from 'classnames';
import React, { ChangeEvent, MouseEvent } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { columnList } from './Data';
import { TasksFoldersTableRow } from '../../../lib/types/tables';
import { HiOutlineFolderOpen } from 'react-icons/hi';

type TasksFoldersTableProps = {
  onItemClick: (value: string) => void;
};

export const TasksFoldersTable: React.FC<TasksFoldersTableProps> = ({
  onItemClick,
}) => {
  const tasksFoldersTableData = useStore(
    (state) => state.tasksFoldersTableData
  );
  const getStatusById = useStore((state) => state.getStatusById);
  const updateTasksFoldersTableData = useStore(
    (state) => state.updateTasksFoldersTableData
  );

  const data = React.useMemo<TasksFoldersTableRow[]>(
    () =>
      tasksFoldersTableData.map(({ id, title, spentTime, statusId }) => {
        return {
          id,
          title: title || '-',
          spentTime: spentTime || '0:00',
          statusId: getStatusById(statusId) || '1',
        };
      }),
    [tasksFoldersTableData, getStatusById]
  );
  const columns = React.useMemo<Column<TasksFoldersTableRow>[]>(
    () => columnList,
    []
  );
  const initialState = { hiddenColumns: ['id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  const onHeaderCheckboxClick = async (e: ChangeEvent<HTMLInputElement>) => {
    const newData = tasksFoldersTableData.map((task) => {
      return { ...task, isChecked: e.target.checked };
    });

    updateTasksFoldersTableData(newData);
  };

  const onCheckBoxClick = (id: string, isChecked: boolean) => {
    const newData = tasksFoldersTableData.map((task) => {
      if (id === task.id) {
        return { ...task, isChecked };
      }
      return task;
    });
    updateTasksFoldersTableData(newData);
  };

  return (
    <table {...getTableProps()} className="min-w-xs w-full text-xs">
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="h-10 px-4">
                <div className="flex justify-start items-center relative">
                  {column.id === 'title' && (
                    <input
                      onChange={(e) => onHeaderCheckboxClick(e)}
                      type="checkbox"
                      className="w-3 h-3 rounded-xl mr-2"
                    />
                  )}
                  {column.render('Header')}
                  <p className="mx-1">
                    <GoTriangleUp className="w-2 h-2 top-[1px] absolute" />
                    <GoTriangleDown className="w-2 h-2 bottom-[1px] absolute" />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="h-10 text-left border-b min-w-[100px] max-w-full"
              onClick={(event: MouseEvent) => {
                const target = event.target as Element;
                if (target.tagName !== 'INPUT' && target.tagName !== 'BUTTON') {
                  onItemClick(row.values.id);
                }
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="px-4">
                    <div
                      className={classNames('flex items-center py-2', {
                        'justify-between px-4': cell.column.id === 'pl',
                      })}
                    >
                      {cell.value && cell.column.id === 'title' && (
                        <>
                          <input
                            type="checkbox"
                            onChange={(event) =>
                              onCheckBoxClick(
                                row.values.id,
                                event.target.checked
                              )
                            }
                            className="w-3 h-3 rounded-xl mr-2"
                          />
                          <HiOutlineFolderOpen className="h-4 w-4 mr-2" />
                        </>
                      )}
                      {cell.render('Cell')}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
