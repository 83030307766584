import { Column } from 'react-table';
import { VacationsTableRow } from '../../../lib/types/tables';

export const columnList: Column<VacationsTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
  {
    Header: 'SPENT',
    accessor: 'spent',
  },
  {
    Header: 'APPROVED',
    accessor: 'approved',
  },
];

export const TableData = [
  {
    id: 'mlkvasd8',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvasd8dsds',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvasd8czxDc',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvadssdasxzsd8czxc',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvasd8dsSACads',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvasd8gbxsczxc',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvacascadssd8czxc',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvasd8sdasxdsds',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvasd8ccxcxzzxc',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
  {
    id: 'mlkvadssd8czsxc',
    date: '01.01.2022',
    spent: '8',
    approved: '8',
  },
];
